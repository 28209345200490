import React, { forwardRef, useImperativeHandle, useState } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/component/submitPopup.module.scss';
//store
import useStore from 'src/webapp/stores/admin.store';

const SubmitPopup = forwardRef((props, ref) => {
    //store
    const { message, setMessage } = useStore();
    //상위컴포넌트에서 호출
    useImperativeHandle(ref, () => ({
        setMessageHandler
    }));
    //메세지내용
    // const [ message, setMessage ] = useState({type:null, label:null});
    const setMessageHandler = (type, label) => {
        let icon = 0;
        switch(type) {
            case 1 : icon = 'submit'; break;
            case 2 : icon = 'error'; break;
            case 3 : icon = 'notice'; break;
        }
        setMessage({type:icon, label:label});
        setTimeout(() => { setMessage({type:null, label:null}); }, 1500);
    }

    return ( 
        <>
        {message.label !== undefined && message.label !== null && message.label.length > 0 ?
            <div className={styles.submitPopupArea}>
                <div>
                    <div>
                        <FontAwesomeIcon className={message.type === 'submit' ? styles.submit : message.type === 'error' ? styles.error : message.type === 'notice' ? styles.notice : null} 
                            icon={message.type === 'submit' ? "fa-light fa-circle-check" : message.type === 'error' ? "fa-light fa-circle-xmark" : message.type === 'notice' ? "fa-light fa-circle-exclamation" : null} />
                        <span>{message.label}</span>
                    </div>
                </div>
            </div>
        : null}
        </>
    );
})
export default SubmitPopup;