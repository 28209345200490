import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
//cookie
import { removeCookie } from 'src/webapp/config/cookie';
//icon
import appLogo_Img from 'src/asset/images/logo/logo192.png';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/component/menu.module.scss';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//Comp
import Arrow from './menu/ChildArrow';

export default React.memo(function Nav(props) {
    const location = useLocation();//현재페이지
    const navigator = useNavigate();//화면이동
    const params = useParams();//파라미터
    //stores
    const { admin } = useAdminStore();
    //메뉴
    const nav = [
        {key:'대시보드', nav:'/dashboard', visible:[`/user/member/reserv/list`], icon:'fa-solid fa-chart-simple'},
        {key:'회원관리', nav:null, icon:'fa-light fa-user-group-simple',
          nodes: [
            {key:'멤버스', nav:'/user/member/list', visible:[`/user/member/${params.cd}`]},
            {key:'파트너스', nav:'/user/partner/list', visible:[`/user/partner/${params.cd}`, `/user/partner/${params.cd}/schedule/list`, `/user/partner/${params.cd}/reg/vehicle`]},
            {key:'파트너스 승인 관리', nav:'/user/partner/approve/list', visible:[]},
          ],
        },
        {key:'알림', nav:null, icon:'fa-light fa-messages',
          nodes: [
            {key:'알림 내역', nav:'/notify/list', visible:[]},
            {key:'알림 발송', nav:'/notify/send', visible:[]},
          ],
        },
        {key:'공지사항', nav:null, icon:'fa-light fa-volume',
            nodes: [
              {key:'공지목록', nav:'/board/list', visible:[]},
            ],
        },
        {key:'기타', nav:null, icon:'fa-light fa-circle-ellipsis-vertical',
            nodes: [
                {key:'제조사 관리', nav:'/brand/item/list', visible:['/brand/list']},
                {key:'통계', nav:'/datas', visible:[]},
            ],
        },
    ];
    
    //set현재페이지아이템
    const [ toggle, setToggle ] = useState(null);
    useEffect(() => {
        nav.filter(function(e, idx) { 
            if(location.pathname === '/') { 
                setToggle(0); return; 
            } else {
                if(JSON.stringify(e).includes(location.pathname)) {
                    setToggle(idx);
                }
            }
        });
    }, [location]);
    //element선택(nav-O:화면이동, nav-X:set하위메뉴)
    const onNavHandler = (item, idx) => {
        const nav = item.nav;
        if(nav != null) {
            navigator(nav, { replace:false }); return;
        }
        setToggle(idx);
    }
    //로그아웃
	const logoutHandler = () => {
		removeCookie('NIDAA');
		removeCookie('NIDRA');
		navigator("/", { replace: true });
	}
      
    return ( 
        <header>
            <div className={styles.userInfo}><img src={appLogo_Img} alt="DUBAKI LOGO" /><span><em>[{admin.DEPT}]</em><br/>{admin.COMN_ID}님, 안녕하세요</span></div>
            <nav>
                <ul>
                    {nav.map((item, idx) => {
                        return (
                            <li key={idx} className={idx === toggle ? styles.selected : null}>
                                <button onClick={(e) => onNavHandler(item, idx)}>
                                    <FontAwesomeIcon icon={item.icon} /><span>{item.key}</span>
                                </button>
                                {idx === toggle && (item.nodes != null) ?
                                    <ul>    
                                        {item.nodes.map((child) => {
                                            return (
                                                <li key={child.key} 
                                                    className={(location.pathname !== "/" && location.pathname === child.nav) || (location.pathname !== "/" && JSON.stringify(child.visible).includes(location.pathname)) ? styles.selected : null}>
                                                    <Link to={child.nav}><Arrow /><span>{child.key}</span></Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                : null}
                            </li> 
                        )
                    })}
                </ul>                
            </nav>
            <button onClick={(e) => logoutHandler()}><FontAwesomeIcon icon="fa-light fa-arrow-right-from-bracket" /><span>로그아웃</span></button>
        </header>
    );
})