import React from 'react';

export default React.memo(function InViewData({inView, readAll}) {

    return ( 
        <>
        {inView ? <div className="inViewLoad">데이터 로딩중</div> 
        : readAll ? <div className="inViewData">데이터를 모두 확인했습니다</div>
        : null}
        </>
    );
})