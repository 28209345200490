import React, { useEffect, useState, useRef } from 'react';
//inview
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Search from './Search';//업체검색
import List from './List';//업체목록
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function PartnerList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    //파트너리스트API
    const isLock = useRef(false);
    const searchRef = useRef([]);
    const pageRef = useRef(20);
    const [ partnerList, setPartnerList ] = useState([]);
    const partnerListAPI = async(type) => {//0:무한로드, 1:검색 
        if((type === 0 && pageRef.current == null) || isLock.current) { return; }

        isLock.current = true;
        pageRef.current = (type === 0 ? pageRef.current : 20) ;
        //const PARTNER_NAME = searchRef.current[0].value != '0' ? searchRef.current[2].value : '';
        const PARTNER_NAME = searchRef.current[2].value;
        const STATE = searchRef.current[1].value === 'ALL' ? '' : searchRef.current[1].value;
        const DATA = { ROWNUM:pageRef.current, PARTNER_NAME:PARTNER_NAME, STATE:STATE };
        const result = await axiosPostHandler(`partnerMasterListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setPartnerList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setPartnerList(pageRef.current === 20 ? result.data : partnerList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
        }
    }
    //파트너카운트API
    const [ cnt,setCnt ] = useState(0);
    const partnerCntAPI = async() => {
        const PARTNER_NAME = searchRef.current[2].value;
        const STATE = searchRef?.current[1]?.value !== 'ALL' ? searchRef.current[1].value : '';
        const DATA = { PARTNER_NAME:PARTNER_NAME, STATE:STATE };
        const result = await axiosPostHandler(`partnerCntByStateForJson.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { setCnt(result.data.result); }
    }
    useEffect(() => {
        partnerListAPI();//파트너리스트API
        partnerCntAPI();//파트너카운트API
    }, []);
    //infinityObserver
    const [ inViewRef,inView ] = useInView({ behavior: 'smooth', threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { partnerListAPI(0); }//알림리스트API
    }, [inView]);
    
    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`파트너스`} cnt={`${cnt}개`}  dept={[{label:'회원관리'}, {label:'파트너스'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <Search search={searchRef} onSearch={partnerListAPI} onState={partnerCntAPI} />
                </FixedPanel>
                <FlickPanel>
                    <List list={partnerList} setPartnerList={setPartnerList} />
                    {partnerList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
                    : partnerList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
                    : null}
                </FlickPanel>
            </Container>
        </Section>
    );
}