import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//styles
import styles from 'src/asset/scss/styles/userPartnerItemInfo.module.scss';
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지

export default function Info() {
    const params = useParams();
    //hook
    const { axiosPostHandler } = useAxiosHook();
    //취급차량리스트
    // const vehiceList = [{name:'125 ET 4-125 ET 4'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    //     , {name:'2024 KR모터스 델리로드 프로100'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    //     , {name:'2024 KR모터스 이스코 트리'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    // ];
    //상품정보
    // const datas = { ITEM_NAME:'SK ZIC TOP 5W30', DESCRIPTION:'맥시마 오토바이 엔진오일 미네랄 기반 부분 합성 오일 1', PRICE:30000, ROYALTY:46000, TIME:1, TOT_PRICE:76000, vehiceList: [{name:'125 ET 4-125 ET 4'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    //     , {name:'2024 KR모터스 델리로드 프로100'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    //     , {name:'2024 KR모터스 이스코 트리'}
    //     , {name:'2024 로얄엔필드 뷸렛 350'}
    // ]};
    const dataFilter = (i, type) => {
        switch(i) {
            case 0 : return type === 0 ? '제품명' : itemInfo.ITEM_NAME ?? null;
            case 1 : return type === 0 ? '설명' : itemInfo.DESCRIPTION ?? null;
            case 2 : return type === 0 ? '단가' : itemInfo.PRICE + " 원" ?? null;
            case 3 : return type === 0 ? '기술료' : itemInfo.ROYALTY + " 원" ?? null;
            case 4 : return type === 0 ? '작업시간' : (itemInfo.DURATION_TIME * 30 + '분') ?? null;
            case 5 : return type === 0 ? '타이어 구분' : itemInfo.TIRE_FR === 1 ? "앞" : itemInfo.TIRE_FR === 2 ? "뒤" : null;
            case 6 : return type === 0 ? '오일명' : itemInfo.OIL_NAME ?? null;
            case 7 : return type === 0 ? '오일수' : itemInfo.OIL_QUOTA > 0 ? itemInfo.OIL_QUOTA + (itemInfo.OIL_UNIT === 1 ? '개' : 'ml') : null;
            case 8 : return type === 0 ? '오일가격' : itemInfo.OIL_PRICE > -1 ? (itemInfo.OIL_PRICE + '원') : null;
            case 9 : return type === 0 ? '총 가격' : itemInfo.TOT_PRICE + " 원" ?? null;
        }
    }

    //아이템상세API
    const [ itemInfo, setItemInfo ] = useState({});
    const itemInfoAPI = async() => {
        const DATA = { ITEM_CD:params.item };
        const result = await axiosPostHandler(`partnerItemByItemForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { setItemInfo({}); }
        if(result.message === "SUCCESS") {
            console.log(result.data);
            setItemInfo(result.data);
        }
    }

    useEffect(() => {
        itemInfoAPI();
    }, [])

    return ( 
        <Element tag={`div`} className={styles.infoContainer}>
            {itemInfo.IMG_SRC === undefined || itemInfo.IMG_SRC === null ? 
                <Element tag={`div`} className={styles.imgNoneArea}><Element tag={`span`}>이미지없음</Element></Element> 
            : <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={itemInfo.IMG_SRC} alt={itemInfo.ITEM_NAME} />}
            <Element tag={`div`} className={styles.infoArea}> 
                <Element tag={`div`}>
                    <Element tag={`span`}>사용차량</Element>
                    {itemInfo.VEHICLE_LIST && itemInfo.VEHICLE_LIST.length > 0 ? itemInfo.VEHICLE_LIST.map((item, i) => 
                        <Element tag={`label`} key={i} className={styles.vehicleList}>{item.BRAND_KOR_NAME + " - " + item.MODEL_KOR_NAME}</Element>
                    ) : null}
                </Element>
                {[...Array(10)].map((x, i) =>
                    dataFilter(i, 1) !== undefined && dataFilter(i, 1) !== null  ?
                        <Element tag={`div`} key={i}>
                            <Element tag={`span`}>{dataFilter(i, 0)} : </Element><Element tag={`label`}>{dataFilter(i, 1)}</Element>
                        </Element>
                    : null
                )}
            </Element>
        </Element>
    );
}