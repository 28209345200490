import React from 'react';
//modalPopup
import Modal from 'react-modal';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//Comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//formInputWrapper
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//formInputTitle

export default function BrandAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
        }
    };
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('update', false);
    }

    return ( 
        <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
            <div className="popupContainer">
                <div className="popupHeader"><h1>제조사 수정</h1></div>
                <div className="popupContainer">
                    <div className="popupFormArea">
                        <FormWrapper><TitleWrapper title={'제조사(브랜드) - 한글'}/>
                            <div className="textArea"><input type="text" placeholder="제조사 한글명을 입력해주세요." /></div>
                        </FormWrapper>
                        <FormWrapper><TitleWrapper title={'제조사(브랜드) - 영문'}/>
                            <div className="textArea"><input type="text" placeholder="제조사 영문명을 입력해주세요." /></div>
                        </FormWrapper>
                        <FormWrapper><TitleWrapper title={'로고 이미지'}/>
                            <div className="fileArea">
                                <label for="file"><span>이미지 업로드</span><FontAwesomeIcon icon="fa-light fa-square-dashed-circle-plus" /></label> 
                                <input type="file" id="file" />
                            </div>
                        </FormWrapper>
                    </div>
                </div>
                <div className="popupFooter">
                    <div className="btnArea">
                        <button onClick={(e) => onCloseHandler()} className="secondaryButton"><span>취소</span></button>
                        <button className="submitButton"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}