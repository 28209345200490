import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//horizontalScroll
import ScrollContainer from 'react-indiana-drag-scroll';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//store
import useStore from 'src/webapp/stores/admin.store';
//comp
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//formInputTitle

export default function PartnerItemExport(props) {
    //hook
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    const { admin } = useStore();//store
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    const { isOpen, items } = props;
    /* ==================== 팝업오픈핸들러 ==================== */
    const onCloseHandler = () => {
        props.onDismiss('export', false);
    }
    /* ==================== 팝업오픈핸들러 ==================== */
    
    /* ==================== 대상업체리스트 ==================== */
    const [ option, setOption ] = useState([{ name:"대상업체 선택하기", value:0, disabled:true }]);
    //대상업체리스트API
    const partnerVehicleRegPartnerAPI = useCallback(async () => {
        if(option.length > 1) { return; }
        const DATA = {PARTNER_CD:0};
        const result = await axiosPostHandler(`partnerVehicleRegPartnerForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message !== "SUCCESS") { }
        if(result.message === "SUCCESS") { 
            setOption(option.concat(result.data));
        }
    }, []);
    /* ==================== 대상업체리스트 ==================== */
    /* ==================== 대상업체셀렉트온체인지 ==================== */
    //checkbox Input, text Input, input select필드 선택한PARTNER_CD
    const [ isCheckedList,setCheckedList ] = useState({PARTNER_CD:0, items:[], checkedItems:[]});
    const [ searchWord,setSearchWord ] = useState('');//검색어state
    //셀렉트한업체 모델ListAPI
    const VehicleIsCheckedList = async(partnerCd) => {
        const DATA = { PARTNER_CD:partnerCd, ITEM_CD:items[0].ITEM_CD };
        const result = await axiosPostHandler(`partnerVehicleIsCheckedListForAdmin.do`, DATA);
        if(result.message !== "SUCCESS") { }
        if(result.message === "SUCCESS") { 
            const data = {PARTNER_CD:partnerCd, items:result.data, checkedItems:(result.data.filter(item => item.USE_YN == "Y"))};
            setSearchWord('');
            setCheckedList(data);
        }
    }
    /* ==================== 대상업체셀렉트온체인지 ==================== */
    /* ==================== 아이템이벤트 ==================== */
    //아이템선택(체크박스)
    const onCheckedHandler = (e, item) => {
        const isChecked = e === null ? false : e.target.checked;
        let array;
        if(isChecked){
            array = [...isCheckedList.checkedItems, item];
        } else{
            array = isCheckedList.checkedItems.filter( (el) => el !== item);
        }
        setCheckedList({...isCheckedList, ['checkedItems']:array});
    }
    //아이템값출력필터
    const vehicleFilter = (item) => {
        return item.BRAND_KOR_NAME + " - " + item.MODEL_KOR_NAME;
    }
    /* ==================== 아이템이벤트 ==================== */
    /* ==================== 아이템복사API ==================== */
    const isLock = useRef(false);
    const partnerItemExportAPI = async() => {
        if(isLock.current) {return;}
        isLock.current = true;
        const DATA = [{ITEM_CD:items[0].ITEM_CD, PARTNER_CD:isCheckedList.PARTNER_CD, LAST_UPDATE_USER:admin.COMN_ID, VEHICLE_LIST:isCheckedList.checkedItems}]
        const result = await axiosPostHandler(`partnerItemExportForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 300);
        if(result.message !== "SUCCESS") { props.onMessage(1, '아이템 복사에 실패하였습니다.');}
        if(result.message === "SUCCESS") { 
            props.onMessage(1, '아이템 복사가 완료되었습니다.');
            onCloseHandler();
            setCheckedList({PARTNER_CD:0, items:[], checkedItems:[]});
        }
    }
    useEffect(() => {
        partnerVehicleRegPartnerAPI();
    } ,[]);

    return ( <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={null} style={popupStyles}>
        <Element tag={`div`} className="popupContainer">
            <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>상품 복사하기</Element></Element>
            <Element tag={`div`} className="popupContainer">
                <Element tag={`div`} className={styles.popupFormArea}>
                    <Element tag={`div`} className={styles.formContainer}>
                        <Element tag={`div`} className={styles.infoContainer}><TitleWrapper title={'대상 아이템'} />
                            <Element tag={`div`} className={styles.infoArea}>
                                <Element tag={`label`}>{items[0].ITEM_NAME}{items[0].DESCRIPTION !== undefined ? (' - ' + items[0].DESCRIPTION) : null}</Element>
                                <Element tag={`span`}>{items[0].TOT_PRICE + " 원"}</Element>
                            </Element>
                        </Element>
                        <Element tag={`div`} className={["formSelectArea", "themeLight" , "popupFormArea", styles.formSelectArea].join(" ")}><TitleWrapper title={'대상 업체'} />
                            <SelectSearch options={option} value={option.value} 
                                onChange={(e) => VehicleIsCheckedList(e)} 
                                isOptionDisabled={(option) => option.disabled}
                                placeholder="대상업체" />
                        </Element>
                        {isCheckedList.items.length > 0 ? 
                            <Element tag={`div`} className={styles.radioContainer}><TitleWrapper title={'등록 차량 선택'} />
                                <Element tag={`div`} className={styles.searchArea}>
                                    <input type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                                    <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
                                </Element>
                                <ScrollContainer horizontal={true} component={`ul`} className={styles.checkedArea}>
                                    {isCheckedList.checkedItems.map((item, i) =>
                                        <Element tag={`li`} key={item.MODEL_CD}>
                                            <Element tag={`label`}>{vehicleFilter(item)}</Element>
                                            <FontAwesomeIcon onClick={(e) => onCheckedHandler(null, item)} icon="fa-light fa-xmark" />
                                        </Element>
                                    )}
                                </ScrollContainer>
                                <Element tag={`div`} className={styles.radioArea}>
                                    {isCheckedList.items.length > 0 ? isCheckedList.items.filter((item) => item.MODEL_KOR_NAME.includes(searchWord) || item.BRAND_KOR_NAME.includes(searchWord)).map((item, i) =>
                                        <Element key={item.MODEL_CD} tag={`div`} className={["radio", "popupRadio", styles.radioWrap, (isCheckedList.checkedItems.includes(item) ? styles.selected : null)].join(" ")}>
                                            <input type='checkbox' onChange={(e) => onCheckedHandler(e, item)} id={`RECEIVE_TYPE${i}`} checked={isCheckedList.checkedItems.includes(item)} />
                                            <Element tag={`label`} htmlFor={`RECEIVE_TYPE${i}`}>{vehicleFilter(item)}</Element>
                                        </Element>
                                    ) : null}
                                </Element>
                            </Element>
                        : null}
                    </Element>
                </Element>
            </Element>
            <Element tag={`div`} className="popupFooter">
                <Element tag={`div`} className="btnArea">
                    <Button anyprop={(e) => onCloseHandler()} class="closeButton" icon={"fa-light fa-xmark"} label={`닫기`} />
                    <Button anyprop={(e) => partnerItemExportAPI()} class="submitButton" label={`확인`} />
                </Element>
            </Element>
        </Element>
    </Modal> )
}