import React, { useState } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnersApprove.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
import ImagePopup from './popup/Image';//이미지팝업
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트

export default function List(props) {
    const checkRef = props.checkRef;//선택값배열
    const telNoRef = props.telNoRef;//전화번호input배열
    const licenseRef = props.licenseRef;//라이센스만료일input배열
    const list = props.list;//data
    console.log(list);
    //상세내역오픈여부
    const [ isOpen,setOpen ] = useState({toggle:null, popup:false});
    //날짜포맷변경
    const dateFormat = (s) => {
        //return s.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
        return s.replaceAll(".", "-");
    }
    //숫자만입력
    const onNumberHandler = (e) => {
        let value = e.target.value;
        const nval = value.replace(/[^0-9]/g, "");
        e.target.value = nval.toString();
    }

    const onApproveHandler = (item, i) => {
        if(telNoRef.current[i].value.length <= 0) {
            props.onMessage(3, '업체 전화번호를 입력해주십시오');
            return;
        }
        props.onApprove(item, i);
    }

    /* ==================== 팝업오픈여부 ==================== */
    //const [ isOpen,setOpen ] = useState({add:false, update:false});
    const [ item,setItem ] = useState(null);
    const onDismissHandler = (name, value, item) => {//type - 1.외관이미지, 2.사업자등록증
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        });
        setItem(value ? item : null);
    }
    /* ==================== 팝업오픈여부 ==================== */
    
    return ( 
        <>
        {list.length > 0 ? 
        <Element tag={`div`} className={styles.listArea}>
            <Element tag={`ul`}>
                {list.map((item, i) => (item.DISPLAY_YN === undefined || item.DISPLAY_YN === 'Y' ?
                    <Element tag={`li`} key={item.PARTNER_CD} className={isOpen.toggle === item ? styles.open : null}>
                        <Element tag={`div`}>
                            <input type='checkbox' ref={el => (checkRef.current[i] = el)} value={JSON.stringify(item)} />
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.PARTNER_NAME} />
                            <Element tag={`div`} style={{width: "7rem"}}><Element tag={`label`}>{item.PARTNER_NAME}</Element></Element><hr />
                            <Element tag={`div`} style={{width: "16.875rem"}}><Element tag={`span`}>{item.ADDR1}</Element><Element tag={`span`}>{item.ADDR2}</Element></Element><hr />
                            <Element tag={`div`} style={{width: "9.25rem"}}><Element tag={`h2`}><input type="text" ref={el => (telNoRef.current[i] = el)} onChange={(e) => onNumberHandler(e)} /></Element><Element tag={`span`}>{item.EMAIL_ADDR}</Element></Element><hr />
                            <Element tag={`div`} style={{width: "8.925rem"}}>
                                <Element tag={`h2`}><input type="date" value={dateFormat(item.REG_DT)} readOnly />신청</Element>
                                <Element tag={`h2`}><input type="date" defaultValue={'2024-12-31'} ref={el => (licenseRef.current[i] = el)} />만료</Element>
                            </Element><hr />
                            <Element tag={`div`} className={styles.stretch}>
                                <Element tag={`h5`} className={item.STATE === '0' ? styles.await : item.STATE === '999' ? styles.reject : null}>{item.STATE === '0' ? '대기' : item.STATE === 999 ? '거절' : null}</Element>
                            </Element>
                            <Element tag={`div`}><Button anyprop={(e) => setOpen({toggle:(item === isOpen.toggle ? null : item), popup:false})} icon={["fa-light", isOpen.toggle === item ? "fa-chevron-up" : "fa-chevron-down"].join(" ")}/></Element>
                        </Element>
                        <Element tag={`article`}>
                            <div onClick={(e) => onDismissHandler('popup', true, item)}>
                                <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.PARTNER_NAME} />
                                <label>외관 이미지</label>
                            </div>
                            <div onClick={(e) => onDismissHandler('popup', true, item)} className={styles.stretch}>
                                <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.BIZ_IMG_SRC} alt={item.PARTNER_NAME} />
                                <label>사업자 등록증</label>
                            </div>
                            <Element tag={`div`} className={styles.btnArea}>
                                <button onClick={(e) => onApproveHandler(item, i)} className={styles.submitButton}>수락하기</button>
                                <button className={styles.rejectButton}>거절하기</button>
                            </Element>
                        </Element>
                    </Element>
                : 
                    <Element tag={`li`} key={item.PARTNER_CD} className={styles.hidden}>
                        <input type='checkbox' ref={el => (checkRef.current[i] = el)} value={JSON.stringify(item)} />
                    </Element>
                ))}
            </Element> 
        </Element>
        : <EmptyData /> }
        <ImagePopup item={item} isOpen={isOpen.popup} onDismiss={onDismissHandler}  />
        </>
    );
}