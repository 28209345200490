import React, { PureComponent, useEffect, useState } from 'react';
//chart
// import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } from "recharts";
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, ResponsiveContainer, Customized,
    Rectangle, } from 'recharts';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트

export default function MemberReservCountLineChart({data}) {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <Element tag={`div`} className={styles.tooltipArea}>
                <Element tag={`div`}><Element tag={`span`}>이용 건수</Element><Element tag={`label`}>{new Intl.NumberFormat('en').format(payload[0].value)}건</Element></Element>
                <Element tag={`div`}><Element tag={`span`}>매출액</Element><Element tag={`label`}>{new Intl.NumberFormat('en').format(payload[0].payload.totBalance)}원</Element></Element>
                {/* <Element tag={`div`}><Element tag={`span`}>매출액</Element><Element tag={`label`}>{new Intl.NumberFormat('en').format(payload[1].value)}원</Element></Element> */}
            </Element>
          );
        }
        return null;
    };

    /* ==================== 차트YAxis넓이동적적용 ==================== */
    const [ width,setWidth ] = useState(0);
    useEffect(() => {(async () => {
        if(data.length > 0) {
            const label = data.map((item) => {
                return item.balance;
            }).reduce((acc, cur) => {
                return cur.toString().length > acc.toString().length ? cur : acc;
            }, {})
            setWidth((label.toString().length + 2) * 4);
        }
    })();
    }, [data]);
    /* ==================== 차트YAxis넓이동적적용 ==================== */

    class MemberReservCountLineChart extends PureComponent {
        render() {
        return (
            <Element tag={`div`} className={styles.lineChartArea}>
                <ResponsiveContainer>
                    <ComposedChart width={"100%"} height={456} data={data}>
                        <defs>
                            <linearGradient id="colorVolumn" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="12.7%" stopColor="#FFD400" stopOpacity={.1}/>
                                <stop offset="99%" stopColor="#191A1A" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#373737" vertical={false} />
                        <XAxis stroke="#A1A1A1" fontSize={12} tickLine={false} unit="월" dataKey="name" scale="point" padding="gap"  /> 
                        <YAxis type="number" tickCount={7} stroke="#A1A1A1" fontSize={12} tickLine={false} width={width} tickFormatter={(value) => new Intl.NumberFormat("en-US", { compactDisplay: "short" }).format(value)} />
                        <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#FFF", strokeWidth: 1, strokeDasharray: "5 5", strokeOpacity: .8 }} />
                        <Bar dataKey="cnt" fill="#737373" barSize={20} radius={[4, 4, 0, 0]} />
                        <Area type="monotone" dataKey="balance" stroke="#FFD400" strokeWidth={2} fillOpacity={1} fill="url(#colorVolumn)" name="매출액" />
                    </ComposedChart>
                </ResponsiveContainer>
            </Element>
        )}
    } return ( <MemberReservCountLineChart /> )
}
