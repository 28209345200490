import React, { useEffect } from 'react';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//hooks
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function BrandList(props) {
    const { pageRef,//무한로딩페이지DOM
        brandList,//브랜드리스트데이터
        brandListAPI//브랜드리스트API
    } = useBrandHook();
    //최초로드
    useEffect(() => {
        brandListAPI(null, null);//브랜드리스트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if(inView && (pageRef.current != null)) { brandListAPI(null, null); }//브랜드리스트API
    }, [inView]);

    return ( 
        <div className={styles.brandListArea}>
            {brandList.length > 0 ? 
            <div className={styles.listArea}>
                <ul>
                    {brandList.map((item, idx) => (
                        <li onClick={(e) => props.choose(item)}>
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={`brand/logo/gray` + item.IMG_SRC} alt={item.BRAND_KOR_NAME} />
                            <span>{item.BRAND_KOR_NAME}</span>
                        </li>
                    ))}
                    {brandList.length > 0 && pageRef.current != null ? <li ref={inViewRef}><InViewData inView={true} /></li> : null}
                </ul> 
            </div>
            : null }
        </div>
    );
}