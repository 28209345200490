import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/brandList.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//Comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지
import ItemToggle from './ItemToggle';//toggleSwitch
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지

const BrandList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        brandDeleteAPI
    }));
    const { axiosPostHandler } = useAxiosHook();//axios
    const { pageRef,//무한로딩페이지DOM
        brandList,//브랜드리스트데이터
        brandListAPI//브랜드리스트API
    } = useBrandHook();
    //최초로드
    useEffect(() => {
        brandListAPI(null, true);//브랜드리스트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { brandListAPI(null, true); }//브랜드리스트API
    }, [inView]);

    const parentRef = useRef({});//메세지컴포넌트ref
    /* ==================== 차량모델삭제 ==================== */
    const checkList = useRef([]);//수락 또는 거절 체크리스트
    const brandDeleteAPI = async() => {
        let DATA = [];
        for(let i = 0; i < checkList.current.length; i++) {
            if(checkList.current[i] != null) {
                const obj = JSON.parse(checkList.current[i].value);
                const BRAND_CD = obj.BRAND_CD;
                if(checkList.current[i].checked) {
                    const obj = { BRAND_CD:BRAND_CD };
                    DATA.push(obj);
                }
            }
        }
        if(DATA.length <= 0) { parentRef.current.setMessageHandler(3, '1개 이상 데이터를 선택해주세요.'); return; } 
        
        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return; }
        const result = await axiosPostHandler(`brandMasterDeleteForJson.do`, DATA);
        if(!result) { parentRef.current.setMessageHandler(2, '일시적인 오류가 발생했습니다.'); }
        if(result.message === "SUCCESS") { 
            parentRef.current.setMessageHandler(1, '삭제가 완료되었습니다.'); 
            brandListAPI(true, true); 
        }
    }
    /* ==================== 차량모델삭제 ==================== */

    return ( 
        <>
        <div className={styles.brandListArea}>
            {brandList.length > 0 ? 
            <ul>
               {brandList.map((item, i) => (
                    <li key={i}>
                        <input type='checkbox' ref={el => (checkList.current[i] = el)} value={JSON.stringify(item)} />
                        <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={`brand/logo/gray` + item.IMG_SRC} alt={item.BRAND_KOR_NAME} />
                        <div style={{width: "5.2rem"}}><label>{item.BRAND_ENG_NAME}</label></div>
                        <div className={styles.stretch}><button onClick={(e) => props.onDismiss('update', true)}><FontAwesomeIcon icon="fa-light fa-pen-to-square" /></button></div>
                        <div className={styles.toggleArea}><ItemToggle item={item} /></div>
                    </li>
                ))}
            </ul>
            : <EmptyData />}
            {brandList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div> : null}
        </div>
        <SubmitPopup ref={parentRef} />
        </>
    )
})
export default BrandList;