import React, { useRef } from 'react';
//store
import useStore from 'src/webapp/stores/admin.store';

const useComnHook = () => {
    /* ==================== 토큰만료기한 설정(refhresh, access) ==================== */
    //refreshToken
    const refreshTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setDate(now.getDate() + 1));
        return expires;
    }
    //accessToken
    const accessTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setHours(now.getHours() + 1));
        return expires;
    }
    /* ==================== 토큰만료기한 설정(refhresh, access) ==================== */
    /* ==================== 메세지팝업처리 ==================== */
    const messageRef = useRef({});//메세지컴포넌트ref
    //메세지핸들러
    const onMessageHandler = (type, label) => {//type - 1.success, 2.error, 3.notice
        messageRef.current.setMessageHandler(type, label);
    }
    /* ==================== 메세지팝업처리 ==================== */
    /* ==================== 입력validate ==================== */
    //숫자만입력
    const onNumberHandler = (e) => {
        let value = e.target.value;
        const nval = value.replace(/[^0-9]/g, "");
        e.target.value = nval.toString();
    }
    //숫자만입력(세자리콤마)
    const onNumberCommaHandler = (e) => {
        let value = e.target.value;
        let nval = Number(value.replaceAll(',', ''));
        if(!isNaN(nval)) { nval = nval.toLocaleString('ko-KR'); } 
        else { nval = ''; }
        //if(isNaN(nval)) { nval = 0;} 
        //else { nval = nval.toLocaleString('ko-KR'); }
        e.target.value = nval;
    };
    /* ==================== 입력validate ==================== */

    //상태필터
    const stateFilter = (type) => {//예약상태- 1.예약진행중, 2.예약완료, 3.입고완료, 4.점검완료, 5.이력등록완료, 6.수락지연취소, 7.미입고취소, 8.취소완료
        switch(type) {
            case "1" : return "예약진행중";
            case "2" : return "예약완료";
            case "3" : return "입고완료";
            case "4" : return "점검완료";
            case "5" : return "이력등록완료";
            case "6" : return "수락지연취소";
            case "7" : return "미입고취소";
            case "8" : return "취소완료";
            default : return "";
        }
    }

    //SubmitPopup sendMessage
    const { setMessage } = useStore();
    const setMessageHandler = (type, label) => {
        let icon = 0;
        switch(type) {
            case 1 : icon = 'submit'; break;
            case 2 : icon = 'error'; break;
            case 3 : icon = 'notice'; break;
        }
        setMessage({type:icon, label:label});
        setTimeout(() => { setMessage({type:null, label:null}); }, 1500);
    }

    return { refreshTokenExpires, accessTokenExpires,
        onMessageHandler, messageRef, setMessageHandler,
        onNumberHandler, onNumberCommaHandler,
        stateFilter
    };
}

export default useComnHook;