import React, { useState, useRef } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';

const useBrandHook = () => {
    const { axiosPostHandler } = useAxiosHook();//axios
    const pageRef = useRef(0);//페이지DOM

    /* ==================== 제조사리스트 ==================== */
    const [ brandList, setBrandList ] = useState([]);
    //제조사리스트API
    const brandListAPI = (async (refresh, isDelete) => {
        if(refresh && isDelete) { pageRef.current = 0; }
        const DATA = { BRAND_CD:pageRef.current, DEL_YN:isDelete ? "" : "N" };
        const result = await axiosPostHandler(`brandMasterListForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { pageRef.current = null; }
        if(result.message == "SUCCESS") { 
            const responseData = pageRef.current === 0 ? result.data : brandList.concat(result.data);
            setBrandList(responseData);
            pageRef.current = (result.data.length >= 20 ? result.data[result.data.length - 1].BRAND_CD : null);

            if(refresh && isDelete) { brandListAPI(null, true); }
        } 
    });
    /* ==================== 제조사리스트 ==================== */
    /* ==================== 제조사별모델리스트 ==================== */
    const checkList = useRef([]);//수락 또는 거절 체크리스트
    const [ modelList, setModelList ] = useState([]);
    //제조사별모델리스트API
    const brandItemListAPI = (async (type, brandCd) => {//type - 0:최초로딩, 1:무한로딩
        pageRef.current = (type === 0 ? 0 : pageRef.current);
        if(type === 0) { checkList.current = []; }
        const DATA = { "BRAND_CD":brandCd, "ROWNUM":pageRef.current };
        const result = await axiosPostHandler(`brandItemListForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { pageRef.current = null; }
        if(result.message == "SUCCESS") { 
            setModelList(pageRef.current === 0 ? result.data : modelList.concat(result.data));
            pageRef.current = (result.data.length >= 20 ? (pageRef.current + 1)  : null);
        } 
    });
    /* ==================== 제조사별모델리스트 ==================== */

    return { pageRef,//페이지DOM정보
        brandListAPI, brandList,//제조사데이터
        brandItemListAPI, checkList, modelList //제조사별모델리스트
    };
}

export default useBrandHook;