import React, { useEffect } from 'react';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//Comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//메뉴
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//메뉴

export default function _index3(props) {
    const { display, inputRef } = props;
    // //props
    // const display = props.display;
    // //입력값배열
    // const inputRef = props.inputRef;
    //제원정보
    const array = ['장르', '배기량(cc)', '연비(km/L)', '엔진형식', '냉각방식', '변속방식(단수)', '최고출력(ps/rpm)', '최대토크(kg.m/rpm)', '전장(mm)', '전폭(mm)', '전고(mm)', '시트고(mm)', '건조중량(kg)', '연료탱크(L)', '타이어(앞)', '타이어(뒤)'];
    /* ==================== validationCheck ==================== */
    const onValidateHandler = () => {
        for(let i = 0; i < inputRef.current.length; i++) {
            if(inputRef.current[i].value.length <= 0) { props.onValid(null, true); return; } 
        }
        props.onValid(null, false);
    }
    useEffect(() => {
        if(display) { onValidateHandler(); }//validateCheck
    }, [display]);
    /* ==================== validationCheck ==================== */

    return ( 
        <div style={{display: props.display ? 'flex' : 'none'}} className="popupFormArea">
            {[...Array(16)].map((x, i) =>
                <FormWrapper key={i}>
                    <TitleWrapper title={array[i]}/>
                    <div className="textArea">
                        <input onChange={(e) => onValidateHandler()}
                            ref={el => (inputRef.current[i] = el)}
                            type="text" inputMode="text" placeholder="내용을 입력해주세요." />
                    </div>
                </FormWrapper>
            )}
        </div>
    );
}