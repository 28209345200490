import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userMembers.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';

export default function List(props) {
    const list = props.list;
    const { stateFilter } = useComnHook();//상태필터

    return ( 
        <>
        {list.length > 0 ? 
            <div className={styles.listArea}>
                <ul>
                    {list.map((item, i) => (
                        <li key={i}>
                            <div style={{width: "7.24rem"}}><label>{item.PARTNER_NAME}</label></div><hr/>
                            <div style={{width: "3.24rem"}}><label>{item.MEMBER_NAME}</label></div><hr/>
                            <div style={{width: "11.94rem"}}><span>예약일 {item.REG_DT}</span></div><hr/>
                            <div style={{width: "11.94rem"}}><span>방문일 {item.RESERV_DT}</span></div><hr/>
                            <div style={{width: "9.24rem"}}><span>{item.MODEL_KOR_NAME}</span></div><hr/>
                            <div style={{width: "5.24rem"}}><span>{item.RESERV_ITEM}</span></div><hr/>
                            <div className={[styles.stretch, ("state" + item.STATE)].join(" ")}><label>{stateFilter(item.STATE)}</label></div>
                            <div><FontAwesomeIcon icon="fa-light fa-chevron-right" /></div>
                        </li>
                    ))}
                </ul>
            </div>
        : <EmptyData /> }
        </>
    );
}