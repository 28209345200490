import React, { useEffect, useState } from 'react';
//modalPopup
import Modal from 'react-modal';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//Comp
import _stepper from './_stepper';//현재단계
import _index01 from './_index01';//차량선택
import _index02 from './_index02';//차량명&이미지입력
import _index03 from './_index03';//제원정보입력

export default function ItemUpdate(props) {
    //cssStyles
    const popupStyles = {
        content: {
          width: "33.125rem",
          height: "100%"
        }
    };
    const [ index, setIndex ] = useState(0);
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('update', false);
        setIndex(0);
    }

    return ( 
        <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
            <div className="popupContainer">
                <div className="popupHeader"><h1>차량 수정</h1>
                    <_stepper index={index} />
                </div>
                <div className="popupContainer">
                    <div>
                        
                        {index === 0 ? <_index01 /> : null}
                        {index === 1 ? <_index02 /> : null}
                        {index === 2 ? <_index03 /> : null}
                    </div>
                </div>
                <div className="popupFooter">
                    <div className="btnArea">
                        <button onClick={(e) => onCloseHandler()} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                        {index > 0 ? <button onClick={(e) => setIndex(index - 1)} className="secondaryButton"><span>이전</span></button> : null}
                        <button onClick={(e) => setIndex(index + 1)} className="submitButton"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}