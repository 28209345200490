import React, { useEffect, useState } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//메뉴
//comp
import MemberReservCountLineChart from './MemberReservCountLineChart';//회원매출액통계차트

export default function MemberReservCount() {
    const { axiosPostHandler } = useAxiosHook();//axios

    /* ==================== 회원예약건수&매출액 ==================== */
    const [ reservTendency,setReservTendency ] = useState([]);
    const memberReservTendencyAPI = async(sort) => {
        const result = await axiosPostHandler(`memberReservTendencyForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            setReservTendency(result.data); 
        } 
    }
    /* ==================== 회원예약건수&매출액 ==================== */
    useEffect(() => {
        memberReservTendencyAPI();//회원예약건수&매출액API
    }, []);

    return ( 
        <Element tag={`div`} className={styles.reservStatsContainer}>
            <Element tag={`div`} className={styles.titleArea}>
                <Element tag={`h1`}>이용 건수 & 매출액</Element>
                <Element tag={`div`}>
                    <Element tag={`span`}>이용 건수</Element>
                    <Element tag={`span`} className={styles.yellow}>매출액(단위:만원)</Element>
                </Element>
            </Element>
            <MemberReservCountLineChart data={reservTendency} />
        </Element>
    );
}