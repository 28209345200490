import React, { useState } from 'react';
//styles
import styles from 'src/asset/scss/styles/notify.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//메뉴
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//메뉴
import FormReceiverList from './FormReceiverList';//메뉴

export default function Form(props) {
    const { inputRef, receiverListRef } = props;

    const [ receiver,setReceiver ] = useState(1);//1:멤버스전체,2:파트너스전체,3:멤버스 지정수신,4:파트너스 지정수신
    //수신자변경핸들러
    const receiverOnChangeHandler = (value) => {
        inputRef.current[4].value = value;
        setReceiver(value);
    }

    return ( 
        <div className={styles.formArea}>
            <FormWrapper><TitleWrapper title={'제목'}/>
                <Element tag={`div`} className={styles.textField}>
                    <input onChange={(e) => {props.onSample('subject', e.target.value); props.onValid()}}
                        ref={el => (inputRef.current[0] = el)}
                        type="text" placeholder="내용을 입력해주세요" />
                </Element>
            </FormWrapper>
            <FormWrapper><TitleWrapper title={'내용'}/>
                <Element tag={`div`} className={styles.textareaField}>
                    <textarea onChange={(e) => {props.onSample('content', e.target.value); props.onValid()}}
                        ref={el => (inputRef.current[1] = el)}
                        placeholder="내용을 입력해주세요"></textarea>
                </Element>
            </FormWrapper>
            <FormWrapper><TitleWrapper title={'타입'}/>
                <div className={styles.radioArea}>
                    <div className="radio">
                        <input name="TYPE" id="TYPE1" type="radio" 
                            onChange={(e) => inputRef.current[2].value = 1}
                            ref={el => (inputRef.current[2] = el)}
                            defaultChecked={true} />
                        <label htmlFor="TYPE1" className="radio-label">광고</label>
                    </div>
                    <div className="radio">
                        <input name="TYPE" id="TYPE2" type="radio" 
                            onChange={(e) => inputRef.current[2].value = 2}
                            ref={el => (inputRef.current[2] = el)} />
                        <label htmlFor="TYPE2" className="radio-label">비광고</label>
                    </div>
                </div>
            </FormWrapper>
            <FormWrapper><TitleWrapper title={'구분'}/>
                <div className={styles.radioArea}>
                    <div className="radio">
                        <input name="RECEIVE_TYPE" id="RECEIVE_TYPE1" type="radio" 
                            onChange={(e) => inputRef.current[3].value = 1}
                            ref={el => (inputRef.current[3] = el)}
                            defaultChecked={true} />
                        <label htmlFor="RECEIVE_TYPE1" className="radio-label">FCM</label>
                    </div>
                    <div className="radio">
                        <input name="RECEIVE_TYPE" id="RECEIVE_TYPE2" type="radio" 
                            onChange={(e) => inputRef.current[3].value = 2}
                            ref={el => (inputRef.current[3] = el)} />
                        <label htmlFor="RECEIVE_TYPE2" className="radio-label">APP</label>
                    </div>
                </div>
            </FormWrapper>
            <FormWrapper><TitleWrapper title={'수신자'}/>
                <div className={styles.radioArea}>
                    <div className="radio">
                        <input name="RECEIVER" id="RECEIVER1" type="radio" 
                            onChange={(e) => receiverOnChangeHandler(1)}
                            ref={el => (inputRef.current[4] = el)}
                            defaultChecked={true} />
                        <label htmlFor="RECEIVER1" className="radio-label">멤버스 전체</label>
                    </div>
                    <div className="radio">
                        <input name="RECEIVER" id="RECEIVER2" type="radio"
                            onChange={(e) => receiverOnChangeHandler(2)}
                            ref={el => (inputRef.current[4] = el)}
                            disabled={true} />
                        <label htmlFor="RECEIVER2" className="radio-label">파트너스 전체</label>
                    </div>
                    <div className="radio">
                        <input name="RECEIVER" id="RECEIVER3" type="radio"
                            onChange={(e) => receiverOnChangeHandler(3)}
                            ref={el => (inputRef.current[4] = el)} />
                        <label htmlFor="RECEIVER3" className="radio-label">지정수신자</label>
                    </div>
                </div>
            </FormWrapper>
            {receiver >= 3 ? <FormReceiverList receiver={receiver} receiverListRef={receiverListRef} /> : null}
        </div>
    );
}