import React, { useEffect, useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//comp
import _index from './_index';//브랜드명&이미지입력

export default function BrandAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
        }
    };
    //stores
    const { admin } = useAdminStore();
    const { axiosPostHandler } = useAxiosHook();//axios
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('add', false);
    }

    /* ==================== 차량등록 ==================== */
    //브랜드정보
    const brandRef = useRef([]);//브랜드기본정보
    //차량등록API
    const parentRef = useRef({});
    const onSubmitHandler = async() => {
        const DATA = { BRAND_KOR_NAME:brandRef.current[0].value, BRAND_ENG_NAME:brandRef.current[1].value, LAST_UPDATE_USER:admin.COMN_ID };
        const result = await axiosPostHandler(`brandMasterRegForJson.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            const resultImage = await parentRef.current.uploadBrandImageAPI(result.data.result);
            setTimeout(() => { onCloseHandler() }, 500);
        } 
    }
    /* ==================== 차량등록 ==================== */

    class BrandAdd extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                <div className="popupContainer">
                    <div className="popupHeader"><h1>제조사 추가</h1></div>
                    <div className="popupContainer">
                        <div className="popupFormArea">
                        <_index ref={parentRef} inputRef={brandRef} onValid={this.onValidItem} />
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="btnArea">
                            <button onClick={(e) => onCloseHandler()} className="secondaryButton"><span>취소</span></button>
                            <button disabled={this.state.isDisabled} onClick={(e) => onSubmitHandler()} className="submitButton"><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )}
    } return ( <BrandAdd /> )
}