import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userMembers.module.scss';

export default function Search(props) {
    //searchData
    const searchRef = props.search;
    //selectOption
    const option1 = [{name:'전체', value:'ALL'}, {name:'회원명', value:'1'}, {name:'닉네임', value:'2'}];
    const option2 = [{name:'전체', value:'ALL'}, {name:'정상', value:'1'}, {name:'접속제한', value:'2'}, {name:'탈퇴', value:'9'}, {name:'삭제', value:'D'}];
    //엔터이벤트
    const handleKeydown = (e) => {
        if(e.key === 'Enter') {
            console.log(e);
            props.onSearch(1);
            props.onState();
        }
    };

    return ( 
        <div className={styles.searchArea}>
            <div className="selectArea">
                <SelectSearch options={option1} defaultValue={searchRef?.current[0]?.value === undefined ? option1[0].value : searchRef?.current[0]?.value}
                    ref={el => (searchRef.current[0] = el)} onChange={el => { (searchRef.current[0].value = el) }}
                    placeholder="회원" />
            </div>
            <div className="selectArea">
                <SelectSearch options={option2} defaultValue={searchRef?.current[1]?.value === undefined ? option2[0].value : searchRef?.current[1]?.value}
                    ref={el => (searchRef.current[1] = el)} onChange={el => { (searchRef.current[1].value = el); props.onSearch(1); props.onState(); }}
                    placeholder="상태" />
            </div>
            <div className={styles.searchField}>
                <input ref={el => (searchRef.current[2] = el)} onKeyDown={handleKeydown}
                    type="text" placeholder="회원을 검색 해보세요" />
                <button><FontAwesomeIcon icon="fa-light fa-magnifying-glass" /></button>
            </div>
        </div>
    );
}