import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/dashboard.module.scss';
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';

export default function ReservList(props) {
    const list = props.list;
    //상태필터
    const stateFilter = (type) => {//예약상태- 1.예약진행중, 2.예약완료, 3.입고완료, 4.점검완료, 5.이력등록완료, 6.수락지연취소, 7.미입고취소, 8.취소완료
        switch(type) {
            case "1" : return "예약진행중";
            case "2" : return "예약완료";
            case "3" : return "입고완료";
            case "4" : return "점검완료";
            case "5" : return "이력등록완료";
            case "6" : return "수락지연취소";
            default : return "";
        }
    }
    
    return ( 
        <>
        {list.length > 0 ? 
            <div className={styles.listArea}>
                <ul>
                    {list.map((item, i) => (
                        <li key={i}>
                            <div style={{width: "7.24rem"}}><label>{item.PARTNER_NAME}</label></div><hr/>
                            <div style={{width: "3.24rem"}}><label>{item.MEMBER_NAME}</label></div><hr/>
                            <div style={{width: "11.94rem"}}><span>예약일 {item.REG_DT}</span></div><hr/>
                            <div style={{width: "11.94rem"}}><span>방문일 {item.RESERV_DT}</span></div><hr/>
                            <div style={{width: "9.24rem"}}><span>{item.MODEL_KOR_NAME}</span></div><hr/>
                            <div style={{width: "5.24rem"}}><span>{item.RESERV_ITEM}</span></div><hr/>
                            <div className={styles.stretch}><label>{stateFilter(item.STATE)}</label></div>
                            <div><FontAwesomeIcon icon="fa-light fa-chevron-right" /></div>
                        </li>
                    ))}
                </ul>
            </div>
        : <EmptyData /> }
        </>
    );
}