import React from 'react';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지

export default function MemberVehicleRankingChild({index, item}) {
    
    return ( 
        <Element tag={`article`}>
            <Element tag={`h2`}>{index + 1}.</Element>    
            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.MODEL_KOR_NAME} />
            <Element tag={`div`}>
                <Element tag={`div`}>
                    <Element tag={`label`}>{item.BRAND_KOR_NAME}</Element>    
                    <Element tag={`span`}>{item.MODEL_KOR_NAME}</Element>    
                </Element>    
                <Element tag={`div`}>
                    <Element tag={`label`}>등록수</Element>    
                    <Element tag={`span`}>{item.CNT}대</Element>    
                </Element>    
            </Element>
        </Element>
    );
}