import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//hooks
import useComnHook from "src/webapp/hooks/comn/comn.hook";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//메세지팝업
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import List from './List';//업체목록
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트

export default function PartnerList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    //stores
    const { admin } = useAdminStore();
    //hook
    const { onMessageHandler, messageRef } = useComnHook();//메세지처리
    /* ==================== 리스트데이터출력 ==================== */
    //파트너리스트API
    const isLock = useRef(false);
    const pageRef = useRef(20);
    const [ partnerList, setPartnerList ] = useState([]);
    const partnerListAPI = async(type) => {//0:무한로드, 1:검색 
        if((type === 0 && pageRef.current == null) || isLock.current) { return; }

        isLock.current = true;
        pageRef.current = (type === 0 ? pageRef.current : 20) ;
        const DATA = { ROWNUM:pageRef.current, STATE:'0' };
        const result = await axiosPostHandler(`partnerMasterListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setPartnerList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setPartnerList(pageRef.current === 20 ? result.data : partnerList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
        }
    }
    useEffect(() => {
        partnerListAPI();//파트너리스트API
    }, []);
    //infinityObserver
    const [ inViewRef,inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { partnerListAPI(0); }//알림리스트API
    }, [inView]);
    /* ==================== 리스트데이터출력 ==================== */
    /* ==================== 가입승인처리 ==================== */
    //가입승인
    const checkList = useRef([]);//수락 또는 거절 체크리스트
    const telNoList = useRef([]);//전화번호input
    const licenseList = useRef([]);//라이센스만료일input
    //수락 및 거절 배열처리
    const partnerApproveArrayHandler = async() => {
        let newList = [];
        let array = [];
        for(let i = 0; i < checkList.current.length; i++) {
            const obj = JSON.parse(checkList.current[i].value);
            const PARTNER_CD = obj.PARTNER_CD;
            let DISPLAY_YN = 'N';
            if(obj.DISPLAY_YN === undefined || obj.DISPLAY_YN === 'Y') {
                if(checkList.current[i].checked) {
                    if(telNoList.current[i].value.length <= 0) {
                        DISPLAY_YN = 'Y';
                        onMessageHandler(3, '업체 전화번호를 입력해주십시오');
                        return;
                    }
                    const obj = {PARTNER_CD:PARTNER_CD, TEL_NO:telNoList.current[i].value, LICENSE_DT:licenseList.current[i].value.replaceAll("-", ""), LAST_UPDATE_USER:admin.COMN_ID};
                    array.push(obj);
                } else {
                    DISPLAY_YN = 'Y';
                }
            }
        }
        if(array.length <= 0) { messageRef.current.setMessageHandler(3, '1개 이상 업체를 선택해주세요.'); return; }
        const result = await partnerApproveAPI(array);
        for(let i = 0; i < array.length; i++) {
            partnerList.filter((item) => { 
                if(item.PARTNER_CD === array[i].PARTNER_CD) { item['DISPLAY_YN'] = 'N'; }
                newList.push(item); 
            });
        }
        setDataHandler(newList);
    }
    //수락 및 거절 단일처리
    const partnerApproveHandler = async(data, i) => {
        const array = [];
        let newList = [];
        partnerList.filter((item) => { if(item.PARTNER_CD === data.PARTNER_CD) { item['DISPLAY_YN'] = 'N'; } newList.push(item); });
        const obj = {PARTNER_CD:data.PARTNER_CD, TEL_NO:telNoList.current[i].value, LICENSE_DT:licenseList.current[i].value.replaceAll("-", ""), LAST_UPDATE_USER:admin.COMN_ID};
        array.push(obj);
        const result = await partnerApproveAPI(array);
        setDataHandler(newList);
    }
    //업체승인API
    const partnerApproveAPI = async(array) => {
        isLock.current = true;
        onMessageHandler(1, '업체 승인을 처리중입니다(실제 반영 최대 1분 소요)');
        for(let i = 0; i < array.length; i++) {
            const DATA = array[i];
            const result = await axiosPostHandler(`partnerMasterRegApproveForJson.do`, DATA);
            setTimeout(() => { isLock.current = false; }, 500);
            if(!result) { onMessageHandler(2, '업체 승인을 처리중 문제가 발생했습니다.'); return; }
            if(result.message === "SUCCESS") { }
        }
    }
    //프론트데이터처리
    const setDataHandler = (list) => {
        let data = list;
        const findItems1 = partnerList.find((item) => item.DISPLAY_YN === 'Y');
        const findItems2 = partnerList.find((item) => item.DISPLAY_YN === undefined);
        if(findItems1 === undefined && findItems2 === undefined) { data = []; }
        setPartnerList(data);
    }
    /* ==================== 가입승인처리 ==================== */

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`파트너스 승인 관리`}  dept={[{label:'회원관리'}, {label:'파트너스 승인 관리'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <ActiveButtonWrapper>
                        <Element tag={`div`}>
                            {admin.LEVEL <= 1 || admin.LEVEL === 9 ? <Button anyprop={(e) => partnerApproveArrayHandler()} label={`수락하기`} /> : null}
                            {admin.LEVEL <= 2 ? <Button class="btnDelete" label={`거절하기`} /> : null}
                        </Element>
                    </ActiveButtonWrapper>
                </FixedPanel>
                <FlickPanel>
                    <List list={partnerList} checkRef={checkList} telNoRef={telNoList} licenseRef={licenseList} onMessage={onMessageHandler} onApprove={partnerApproveHandler} />
                    {partnerList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
                    : partnerList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
                    : null}
                </FlickPanel>
                <SubmitPopup ref={messageRef} />
            </Container>
        </Section>
        </>
    );
}