import React, { useState } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지컴포넌트
import PartnerUpdatePopup from './popup/PartnerUpdate';//업체정보수정-팝업
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import SubmitPopup from '../../component/SubmitPopup';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

export default function Info({info, infoApi}) {
    const { onMessageHandler, messageRef } = useComnHook();//메세지처리
    /* ==================== 업체상태필터 ==================== */
    const partnerStateFilter = (element) => {
        switch(element) {
            case "1" : return "이용중";
            case "2" : return "이용제한";
            case "3" : return "접속제한";
            case "4" : return "비제휴업체";
            case "9" : return "탈퇴";
            case "D" : return "삭제";
        }
    }
    /* ==================== 업체상태필터 ==================== */
    /* ==================== 팝업오픈여부 ==================== */
    const [ isOpen,setOpen ] = useState({update:false});
    const onDismissHandler = (name, value) => {
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ==================== 팝업오픈여부 ==================== */

    return ( 
        <>
        <Element tag={`div`} className={styles.basicInfo}>
            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={info.IMG_SRC_1} alt={info.PARTNER_NAME} />
            <Element tag={`div`} className={styles.infoTextArea}>
                <Element tag={`div`} className={styles.boxLine1}>
                    <Element tag={`div`}>
                        <Element tag={`span`} className={styles.primary}>{partnerStateFilter(info.STATE)}</Element><hr/>
                        <Element tag={`span`}><FontAwesomeIcon icon="fa-light fa-calendar" />{info.REG_DT} 가입</Element><hr/>
                        <Element tag={`span`}><FontAwesomeIcon icon="fa-light fa-calendar" />{info.LICENSE_DT} 만료</Element>
                    </Element>
                    <Element tag={`div`}>
                        <Button anyprop={(e) => onDismissHandler('update', true)} icon={"fa-light fa-pen-to-square"} />
                    </Element>
                </Element>
                <Element tag={`div`}  className={styles.boxLine2}>
                    <Element tag={`label`}>{info.PARTNER_NAME}</Element><Element tag={`em`}>{info.PARTNER_ID}</Element>
                </Element>
                <Element tag={`div`}  className={styles.boxLine3}>
                    <Element tag={`span`}>{info.TEL_NO}</Element><hr/>
                    <Element tag={`span`}>{info.EMAIL_ADDR}</Element><hr/>
                    <Element tag={`span`}>{info.ADDR1}{info.ADDR2 !== undefined ? ' ' + info.ADDR2 : null}</Element><hr/>
                    {/* <span>활성</span><hr/> */}
                    <Element tag={`span`}>{info.REG_DT} 가입</Element>
                </Element>
                {info.SUMMARY !== undefined ? <Element tag={`div`} ><pre dangerouslySetInnerHTML={{__html: info.SUMMARY}}></pre></Element> : null}
                <Element tag={`div`}  className={styles.boxArea}>
                    {info.START_BIZ_STR !== undefined && info.END_BIZ_STR !== undefined ? <Element tag={`div`} >영업시간 {info.START_BIZ_STR + '-' + info.END_BIZ_STR}</Element> : null}
                    {info.START_BREAK_STR !== undefined && info.END_BREAK_STR !== undefined ? <Element tag={`div`} >휴게시간 {info.START_BREAK_STR + '-' + info.END_BREAK_STR}</Element> : null}
                    <Element tag={`div`} >정비인원 {info.MECHANIC}명</Element>
                    {info.RECOMMEND_ID !== undefined ? <Element tag={`div`} >추천코드 {info.RECOMMEND_ID}</Element> : null}
                </Element>
            </Element>
        </Element>
        {isOpen.update ? <PartnerUpdatePopup isOpen={isOpen.update} onMessageHandler={onMessageHandler} info={info} infoApi={infoApi} onDismiss={onDismissHandler} /> : null}
        <SubmitPopup ref={messageRef} />
        </>
    );
}