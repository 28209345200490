import React from 'react';
//styles
import styles from 'src/asset/scss/component/titlebar.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div

export default React.memo(function Titlebar(props) {
    //props
    const title = props.title;
    const cnt = props.cnt;
    const dept = props.dept;

    return ( 
        <Element tag={`div`} className={styles.titleArea}>
            <Element tag={`div`}>
                <Element tag={`h2`}>{title}</Element>{cnt !== undefined && cnt !== null ? <Element tag={`em`}>{cnt}</Element> : null}
            </Element>
            <Element tag={`div`}>
                {dept.length > 0 && dept.map((item, idx) => {
                    return (
                        <Element tag={`span`} key={idx}>
                            {idx > 0 ? <FontAwesomeIcon icon="fa-light fa-chevron-right" /> : null}
                            <Element tag={`span`}>{item.label}</Element>
                        </Element>
                    )
                })}
            </Element>
        </Element>
    );
})