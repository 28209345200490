import React, { useState, useRef } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';

const useMemberHook = () => {
    const { axiosPostHandler } = useAxiosHook();//axios
    const isLock = useRef(false);//더블클릭방지DOM
    
    //회원상태정보변경
    const updateStateMemberAPI = async(value, data) => {
        if(isLock.current) { return; }

        let str = '';
        switch(value) {
            case '1' : str = '정상'; break;
            case '2' : str = '접속제한'; break;
            case '9' : str = '탈퇴'; break;
            case 'D' : str = '삭제'; break;
        }
        if(!window.confirm(str + '(으)로 상태를 변경하시겠습니까?')) { return false; }

        isLock.current = true;
        const DATA = { MEMBER_CD:data.MEMBER_CD, STATE:value };
        return await updateMemberAPI(DATA);
    }
    
    //회원정보수정API
    const updateMemberAPI = async(DATA) => {
        isLock.current = true;

        const result = await axiosPostHandler(`memberMasterUpdateForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result || result.message !== "SUCCESS") { return false; }
        if(result.message === "SUCCESS") { return true; }
    }

    return { 
        updateStateMemberAPI, updateMemberAPI//회원정보업데이트
    };
}

export default useMemberHook;