import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import styles from 'src/asset/scss/styles/notify.module.scss';
//icon
import appLogo_Img from 'src/asset/images/logo/logo192.png';

const NotifySample = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        addSampleDataHandler(name, value) {
            value = value.length > 0 ? value : (name === 'subject' ? '제목' : '내용' + '을 입력해주세요.');
            setData((prevState) => {
                return { ...prevState, [name]:value }
            })
        }, 
        showSampleDataHandler(data) {
            setData(data);
        }
    }));

    const [ data,setData ] = useState({subject:'제목을 입력해주세요.', content:'내용을 입력해주세요.', type:0, regTime:null});//샘플데이터
    /* ==================== 현재시간(hh:mm) ==================== */
    const currentTimeHandler = () => {
        const date = new Date();
        const hours = new String(date.getHours()).padStart(2, "0");
        const minutes = new String(date.getMinutes()).padStart(2, "0");
        const seconds = new String(date.getSeconds()).padStart(2, "0");
        return (`${hours}:${minutes}`)
    }
    useEffect(() => {
        setData((prevState) => {
            return { ...prevState, ['regTime']:currentTimeHandler() }
        })
    }, []);
    /* ==================== 현재시간(hh:mm) ==================== */

    return ( 
        <div className={styles.notifySampleArea}>
            <div><span>미리보기</span>
                <article>
                    <img src={appLogo_Img} alt="DUBAKI" />
                    <div>
                        <div><label>{data.subject}</label><span>{data.regTime}</span></div>
                        <pre>{data.type === 1 ? '(광고)' : null}{data.content}</pre>
                    </div>
                </article>
            </div>
            <div><span>미리보기</span>
                <article>
                    <div><label>{data.subject}</label><span>{data.regTime}</span></div>
                    <pre>{data.content}</pre>
                </article>
            </div>
        </div>
)
})
export default NotifySample;