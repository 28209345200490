import React from 'react';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목

export default function _index(props) {
    const inputRef = props.inputRef;
    const data = props.data;
    //validateCheck
    const onValidateHandler = () => {
        //입력값검증*기존의값이랑 같으면 버튼비활성화
        if(data !== null && (inputRef.current[0].value === data.current.SUBJECT && inputRef.current[1].value === data.current.CONTENT && Number(inputRef.current[2].value) === data.current.TYPE)) {
            props.onValid(null, true);
            return; 
        }
        for(let i = 0; i < inputRef.current.length; i++) {
            if(inputRef.current[i].value.length <= 0) { props.onValid(null, true); return; } 
        }
        props.onValid(null, false);
    }
    
    return ( 
        <Element tag={`div`} className="popupFormArea">
            <FormWrapper>
                <TitleWrapper title={'제목'}/>
                <Element tag={`div`} className="textArea">
                    <input onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[0] = el)}
                        type="text" inputMode="text" placeholder="내용을 입력해주세요."
                        defaultValue={data !== null ? data.current.SUBJECT : null} />
                </Element>
            </FormWrapper>
            <FormWrapper>
                <TitleWrapper title={'내용'}/>
                <Element tag={`div`} className="textarea">
                    <textarea onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[1] = el)}
                        placeholder="내용을 입력해주세요."
                        defaultValue={data !== null ? `${data.current.CONTENT}` : null} />
                </Element>
            </FormWrapper>
            <FormWrapper>
                <TitleWrapper title={'수신자'}/>
                <Element tag={`div`} className="radioArea">
                    <Element tag={`div`} className="radio">
                        <input name="TYPE" id="TYPE-1" type="radio" 
                            onChange={(e) => {inputRef.current[2].value = 1; onValidateHandler()}}
                            ref={el => (inputRef.current[2] = el)} value={1}
                            defaultChecked={data === null || data.current.TYPE === 1 ? true : false} />
                        <label htmlFor="TYPE-1" className="radio-label">멤버스</label>
                    </Element>
                    <Element tag={`div`} className="radio">
                        <input name="TYPE" id="TYPE-2" type="radio" disabled={true}
                            onChange={(e) => {inputRef.current[2].value = 2; onValidateHandler()}}
                            ref={el => (inputRef.current[2] = el)}
                            defaultChecked={data !== null && data.current.TYPE === 2 ? true : false} />
                        <label htmlFor="TYPE-2" className="radio-label">파트너스</label>
                    </Element> 
                </Element>
            </FormWrapper>
        </Element>
    );
}