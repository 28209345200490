import React from 'react';
//Comp
import Title from './Title';//알림발송작성

function Form(props) {
   const {children, className} = props;

   return <div className={className}>{children}</div>
}
 
export default Form;