import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//styles
import styles from 'src/asset/scss/styles/userPartnerList.module.scss';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//hooks
import useComnHook from "src/webapp/hooks/comn/comn.hook";
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//실행처리팝업

export default function List({list, setPartnerList}) {
    const { axiosPostHandler } = useAxiosHook();//axios
    //stores
    const { admin } = useAdminStore();
    //hook
    const { onMessageHandler, messageRef } = useComnHook();//메세지처리
    /* ==================== 업체상태옵션 ==================== */
    const option = [{name:'승인대기', value:'0'}, {name:'구독 중', value:'1'}, {name:'이용제한', value:'2'}, {name:'접속제한', value:'3'}, {name:'비제휴업체', value:'4'}, {name:'탈퇴', value:'9'}, {name:'삭제', value:'D'}];
    const partnerStateFilter = (element) => {
        switch(element) {
            case '0' : return '승인대기';
            case '1' : return '구독 중';
            case '2' : return '이용제한';
            case '3' : return '접속제한';
            case '4' : return '비제휴업체';
            case '9' : return '탈퇴';
            case 'D' : return '삭제';
        }
    }
    /* ==================== 업체상태옵션 ==================== */
    /* ==================== 업체상태수정 ==================== */
    //업체상태변경핸들러
    const isLock = useRef(false);
    const updatePartnerStateHandler = async(value, data, i) => {
        if(isLock.current) { return; }
        if(value === "0" || data.STATE === "0" || value === "4" || data.STATE === "4") {
            let msg = "";
            if(value === "0" || data.STATE === "0") { msg = "파트너스 승인 관리 메뉴에서 처리가능합니다."; }
            if(value === "4" || data.STATE === "4") { msg = "비제휴업체는 상태 변경이 불가능합니다."; }
            onMessageHandler(3, msg);
            return;
        }

        const str = partnerStateFilter(value);
        if(!window.confirm(str + '(으)로 상태를 변경하시겠습니까?')) { return false; }

        isLock.current = true;
        const DATA = { PARTNER_CD:data.PARTNER_CD, STATE:value };
        const result = await axiosPostHandler(`partnerMasterUpdateForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result || result.message !== "SUCCESS") { return false; }
        if(result.message === "SUCCESS") { 
            updateFieldHandler('STATE', i, value); 
            onMessageHandler(1, data.PARTNER_NAME + "님의 상태가 변경되었습니다."); 
        }
    }
    //상태변경핸들러
    const updateFieldHandler = (name, index, value) => {
        let newArr = list.map((item, i) => {
            if(index === i && value !== null) { return { ...item, [name]: value }; } 
            return item;
        });
        setPartnerList(newArr);
    };
    /* ==================== 업체상태수정 ==================== */

    return ( 
        <>
        {list.length > 0 ? 
        <Element tag={`div`}  className={styles.listArea}>
            <ul>
                {list.map((item, i) => (
                    <li key={item.PARTNER_CD}>
                        <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.PARTNER_NAME} />
                        <Link to={item.STATE !== 4 ? `/user/partner/${item.PARTNER_CD}` : null} style={{width: "8.5rem"}}>
                            <span>{item.RECOMMEND_ID ?? '추천코드 없음'}</span><label>{item.PARTNER_NAME}</label>
                        </Link><hr />
                        <Element tag={`div`} style={{width: "16.875rem"}}><span>{item.ADDR1}</span><span>{item.ADDR2}</span></Element><hr />
                        <Element tag={`div`} style={{width: "9.25rem"}}><span>{item.TEL_NO}</span><span>{item.EMAIL_ADDR}</span></Element><hr />
                        <Element tag={`div`} style={{width: "6.5rem"}}>
                            <span>{item.REG_DT} 가입</span>
                            <span>{item.STATE === '1' && item.LICENSE_DT != null ? item.LICENSE_DT + ' 만료' : null}</span>
                        </Element><hr />
                        <Element tag={`div`} className="listAreaSelectFilter">
                            <SelectSearch options={option} value={item.STATE} onChange={(e) => updatePartnerStateHandler(e, item, i)} placeholder="상태" />
                            <span></span>
                        </Element><hr />
                        <Element tag={`div`} className={styles.stretch}><h5>{item.STATE === '3' ? '블랙리스트' : null}</h5></Element>
                        <Element tag={`div`}>{admin.LEVEL <= 2 ?<button><FontAwesomeIcon icon="fa-light fa-trash-can" /><span>삭제</span></button> : null}</Element>
                    </li>
                ))}
            </ul> 
        </Element>
        : <EmptyData /> }
        <SubmitPopup ref={messageRef} />
        </>
    );
}