import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//hooks
import useMemberHook from 'src/webapp/hooks/member/member.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';

const Info = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        memberVehicleCntChangeHandler(value) {
            setInfo({ ...info, ['VEHICLE_CNT']:value});     
        }
    }));
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    //hooks
    const { onNumberHandler } = useComnHook();//숫자만입력
    //stores
    const { admin } = useAdminStore();
    /* ==================== 회원상태옵션 ==================== */
    //selectOption
    const option = [{name:'정상', value:'1'}, {name:'접속제한', value:'2'}, {name:'탈퇴', value:'9'}, {name:'삭제', value:'D'}];
    /* ==================== 회원상태옵션 ==================== */
    /* ==================== 회원정보 ==================== */
    //회원정보API
    const [ info,setInfo ] = useState({});
    const memberInfoAPI = async() => {
        const DATA = { MEMBER_CD:params.cd };
        const result = await axiosPostHandler(`memberInfoForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { 
            setInfo(result.data);
        }
    }
    useEffect(() => {
        memberInfoAPI();//회원정보API
    }, []);
    /* ==================== 회원정보 ==================== */
    const { updateStateMemberAPI//회원상태변경API
        , updateMemberAPI//회원정보수정API
    } = useMemberHook();
    /* ==================== 회원상태변경 ==================== */
    //회원상태변경핸들러
    const updateMemberStateHandler = async(value) => {
        const result = await updateStateMemberAPI(value, info);
        if(result) { 
            setInfo({ ...info, ['STATE']:value});     
            props.onMessage(1, info.MEMBER_NAME + "님의 상태가 변경되었습니다.");
        }
    }
    /* ==================== 회원상태변경 ==================== */
    /* ==================== 회원정보수정 ==================== */
    const inputRef = useRef([]);//회원수정데이터ref
    //회원정보수정핸들러
    const updateMemberHandler = async() => {
        if(!window.confirm(info.MEMBER_NAME + "님의 회원정보를 수정하시겠습니까?")) { return; }

        const MEMBER_NAME    = info.MEMBER_NAME !== inputRef.current[0].value ? inputRef.current[0].value : '';
        const NICK_NAME      = info.NICK_NAME !== inputRef.current[1].value ? inputRef.current[1].value : '';
        const EMAIL_ADDR     = info.EMAIL_ADDR !== inputRef.current[2].value ? inputRef.current[2].value : null;
        const TEL_NO         = info.TEL_NO !== inputRef.current[3].value ? ('+82' + Number(inputRef.current[3].value)) : null;
        const DATA           = { MEMBER_CD:params.cd, MEMBER_NAME:MEMBER_NAME, NICK_NAME:NICK_NAME, EMAIL_ADDR:EMAIL_ADDR, TEL_NO:TEL_NO };
        const result = await updateMemberAPI(DATA);
        if(result) {
            props.onMessage(1, info.MEMBER_NAME + "님의 정보가 변경되었습니다.");
        }
        if(result && TEL_NO !== null) {
            inputRef.current[3].value = '+82' + Number(inputRef.current[3].value);
        }
    }
    /* ==================== 회원정보수정 ==================== */

    return ( 
        <Element tag={`div`} className={styles.basicInfo}>
            {/* 회원정보수정 */}
            {admin.LEVEL <= 1 || admin.LEVEL === 9 ?
                <ActiveButtonWrapper>
                    <Element tag={`div`} >
                        <button className="btnDelete" onClick={(e) => updateMemberHandler()}><span>수정하기</span></button>
                    </Element>
                </ActiveButtonWrapper>
            : null}
            <Element tag={`ul`}>
                <Element tag={`li`}>
                    <Element tag={`div`} style={{width: "5rem"}}>
                        <Element tag={`label`} className={styles.focusField} style={{width: "5rem"}}>
                            <input type="text" ref={el => (inputRef.current[0] = el)} defaultValue={info.MEMBER_NAME} placeholder="회원명" />
                        </Element>
                    </Element><hr/>
                    <Element tag={`div`} style={{width: "7.5rem"}}>
                        <Element tag={`label`} className={styles.focusField} style={{width: "7.5rem"}}>
                            <input type="text" ref={el => (inputRef.current[1] = el)} defaultValue={info.NICK_NAME} placeholder="닉네임" />
                        </Element>
                    </Element><hr/>
                    <Element tag={`div`} style={{width: "12rem"}}>
                        <Element tag={`span`} className={styles.focusField} style={{width: "12rem"}}>
                            <input type="text" ref={el => (inputRef.current[2] = el)} defaultValue={info.EMAIL_ADDR} placeholder="이메일주소" />
                        </Element>
                    </Element><hr/>
                    <Element tag={`div`} style={{width: "9.5rem"}}>
                        <Element tag={`span`} className={styles.focusField} style={{width: "9.5rem"}}>
                            <input type="text" ref={el => (inputRef.current[3] = el)} onChange={(e) => onNumberHandler(e)} defaultValue={info.TEL_NO} placeholder="휴대폰번호" />
                        </Element>
                    </Element><hr/>
                    <Element tag={`div`} style={{width: "5.25rem"}}><Element tag={`span`}>등록차량 {info.VEHICLE_CNT}대</Element></Element><hr/>
                    <Element tag={`div`} style={{width: "25.25rem"}} className={"listAreaSelectFilter"}>
                        <SelectSearch options={option} defaultValue={info.STATE} value={info.STATE} onChange={(e) => updateMemberStateHandler(e)} placeholder="상태" />
                    </Element>
                    <Element tag={`div`} className={styles.stretch}></Element>
                    <Element tag={`div`} className={styles.regDate}><FontAwesomeIcon icon="fa-light fa-calendar" /><span>{info.REG_DT}</span></Element>
                </Element>
            </Element>
        </Element>
 	)
})
export default Info;