import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import SubtitleArea from 'src/webapp/views/wrapper/SubtitleArea';//서브타이틀공통컴포넌트
//comp
import VehicleUpdate from './popup/VehicleUpdate';//주행거리수정팝업

export default function VehicleList(props) {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    //회원등록차량리스트API
    const [ vehicleList,setVehicleList ] = useState([]);
    const memberVehicleListAPI = async() => {
        const DATA = { MEMBER_CD:params.cd };
        const result = await axiosPostHandler(`memberVehicleListForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { 
            setVehicleList(result.data);
        }
    }
    useEffect(() => {
        memberVehicleListAPI();//회원등록차량리스트API
    }, []);
    /* ==================== 차량삭제 ==================== */
    //회원등록차량삭제핸들러
    const checkedRef = useRef([]);
    const deleteMemberVehicleHandler = async() => {
        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return; }

        let array = [];
        let DATA = [];
        for(let i = 0; i < vehicleList.length; i++) {
            if(checkedRef?.current[i] !== undefined && checkedRef.current[i].checked) {
                DATA.push({ MEMBER_CD:params.cd, ITEM_CD:vehicleList[i].ITEM_CD, DEL_YN:"Y", USE_YN:"N" });
                array.push(vehicleList[i]);
            }
        }
         
        const result = await axiosPostHandler(`memberVehicleDeleteForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { 
            const newList = vehicleList.filter((list) => {
                return !array.includes(list);
            })
            setVehicleList(newList);
            onMessageHandler(1, '차량 삭제가 완료되었습니다.');
            props.onChange(newList.length);
        }
    }
    /* ==================== 차량삭제 ==================== */
    /* ==================== 주행거리 수정팝업 ==================== */
    const [ isOpen,setOpen ] = useState({update:false});
    const dataRef = useRef({});
    const onDismissHandler = (name, value, item) => {
        if(value) { dataRef.current = item !== null ? item : {}; }
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ==================== 주행거리 수정팝업 ==================== */
    /* ==================== 메세지출력 ==================== */
    const onMessageHandler = (type, message) => {
        props.onMessage(type, message);
    }
    /* ==================== 메세지출력 ==================== */

    return ( 
        <>
        {vehicleList.length > 0 ? 
        <Element tag={`div`}  className={styles.vehicleListArea}>
            <Element tag={`div`} className={styles.titleArea}>
                <SubtitleArea title={'등록차량'} >
                    <Button anyprop={(e) => deleteMemberVehicleHandler()} class={"subtitle"} icon="fa-light fa-trash-can" label={"선택삭제"} ></Button>
                </SubtitleArea>
            </Element>
            <Element tag={`ul`}>
                {vehicleList.length > 0 && vehicleList.map((item, i) =>
                    <Element tag={`li`} key={item.ITEM_CD}>
                        <Element tag={`div`} className={styles.vehicleInfo}>
                            <Element tag={`div`} >
                                <Element tag={`label`}>{item.BRAND_KOR_NAME + ' ' + item.MODEL_KOR_NAME}</Element>
                                <input type='checkbox' ref={el => (checkedRef.current[i] = el)}  />
                            </Element>
                            <Element tag={`div`} >
                                <Element tag={`span`}>{item.LIC_NO}</Element>
                                <Element tag={`span`}>{item.VIN_NO !== undefined ? item.VIN_NO : '차대번호 없음'}</Element>
                            </Element>
                        </Element>
                        <Element tag={`hr`} />
                        <Element tag={`div`}  className={styles.vehicleLogArea}>
                            <Element tag={`div`}  className={styles.vehicleLog}>
                                <Element tag={`label`}><FontAwesomeIcon icon="fa-light fa-coins" />주행거리<Element tag={`strong`}>{item.KMLE}km</Element><Button anyprop={(e) => onDismissHandler('update', true, item)} icon="fa-light fa-pen-to-square" label={""} ></Button></Element>
                                <Element tag={`label`}><FontAwesomeIcon icon="fa-light fa-coins" />지출금액<Element tag={`strong`}>{item.ACCOUNT}원</Element></Element>
                            </Element>
                            <Element tag={`div`} >
                                <Element tag={`label`}>{item.INS_KOR_NAME !== undefined ? item.INS_KOR_NAME : '등록된 보험 없음'}{item.INS_CNT > 0 ? ' 외' + item.INS_CNT : null}</Element>
                                <Element tag={`div`} >
                                    <Element tag={`span`}>{item.INS_START_DT !== undefined ? '가입일 ' + item.INS_START_DT : null}</Element>
                                    <Element tag={`span`}>{item.INS_START_DT !== undefined ? '만기일 ' + item.INS_END_DT : null}</Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                )}
            </Element>
        </Element>
        : null }
        {isOpen.update ? <VehicleUpdate isOpen={isOpen.update} data={dataRef} onDismiss={onDismissHandler} onMessage={onMessageHandler} /> : null}
        </>
    );
}