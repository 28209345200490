import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = async(name, value, options) => {
  cookies.set(name, value, {...options})
  return true;
}

export const getCookie = (name) => {
  return cookies.get(name);
}

export const removeCookie = async(name) => {
  cookies.remove(name, {path: "/"})
  return true;
}