import React, { useEffect, useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//comp
import _index from "./_index";

export default function BoardAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
        }
    };
    //stores
    const { admin } = useAdminStore();
    const { axiosPostHandler } = useAxiosHook();//axios
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = (type) => {
        props.onDismiss('add', false, type);
    }
    //입력Ref
    const inputRef = useRef([]);//브랜드기본정보

    /* ==================== 공지사항등록 ==================== */
    const boardRegAPI = async() => {
        const TYPE = inputRef.current[2].value !== 'on' ? inputRef.current[2].value : 1;
        const DATA = { SUBJECT:inputRef.current[0].value, CONTENT:inputRef.current[1].value, TYPE:TYPE, REG_ID:admin.COMN_ID };
        const result = await axiosPostHandler(`boardNoticeRegForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "SUCCESS") { onCloseHandler(1); }
    }
    /* ==================== 공지사항등록 ==================== */

    class BrandAdd extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                <Element tag={`div`} className="popupContainer">
                    <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>공지 작성</Element></Element>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupFormArea">
                            <_index inputRef={inputRef} data={null} onValid={this.onValidItem} />
                        </Element>
                    </Element>
                    <Element tag={`div`} className="popupFooter">
                        <Element tag={`div`} className="btnArea">
                            <Button anyprop={(e) => onCloseHandler(2)} class="secondaryButton" label={"취소"} ></Button>
                            <Button anyprop={(e) => boardRegAPI()} disabled={this.state.isDisabled} class="submitButton" label={"등록하기"} ></Button>
                        </Element>
                    </Element>
                </Element>
            </Modal>
        )}
    } return ( <BrandAdd /> )
}