import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//modalPopup
import Modal from 'react-modal';
import 'src/asset/scss/plugin/modalPopup.scss';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//store
import useStore from 'src/webapp/stores/admin.store';
//compWrapper
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지컴포넌트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//formInputWrapper
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//formInputTitle

export default function ItemAdd(props) {
    const { isOpen, info } = props;
    const params = useParams();
    //hook
    const { axiosPostHandler } = useAxiosHook();
    //store
    const { admin } = useStore();
    //cssStyles
    const popupStyles = {
        content: {
          width: "33.125rem",
          height: "100%"
        }
    };
    /* ==================== 팝업오픈여부 ==================== */
    const onCloseHandler = () => {
        props.onDismiss('update', false);
    }
    /* ==================== 팝업오픈여부 ==================== */
    /* ==================== 파트너수정 ==================== */
    const partnerUpdateRef = useRef([]);
    const isLock = useRef(false);
    const partnerUpdateAPI = (async() => {
        if(isLock.current) {return;}
        if(partnerUpdateRef.current[1].value.length > 0 ){
            const regPwd = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^()-])[A-Za-z\d$@$!%*#?&^()-]{8,}$/g;
            if(!regPwd.test(partnerUpdateRef.current[1].value)) { alert('비밀번호가 형식에 맞지않습니다'); return; }
        }
        const DATA = { "PARTNER_CD":params.cd, "PARTNER_NAME":partnerUpdateRef.current[0].value, "PASS_WD":partnerUpdateRef.current[1].value ?? null, "TEL_NO":partnerUpdateRef.current[2].value ?? null, 
            "EMAIL_ADDR":partnerUpdateRef.current[3].value ?? '', "ADDR1":partnerUpdateRef.current[4].value ?? '', "LAST_UPDATE_USER":admin.COMN_ID, 
            "ADDR2":partnerUpdateRef.current[5].value ?? '', "MECHANIC":partnerUpdateRef.current[6].value ?? info.MECHANIC, "SUMMARY":partnerUpdateRef.current[7].value ?? null, };
        const result = await axiosPostHandler(`partnerMasterUpdateForAdmin.do`, DATA);
        setTimeout(() => {isLock.current = false;}, 300);
        if(!result) { return; }
        if(result.message != "SUCCESS") { props.onMessageHandler(2, '일시적인 오류가 발생했습니다.'); }
        if(result.message == "SUCCESS") { 
            props.onMessageHandler(1, '파트너스정보 수정이 완료되었습니다.'); 
            props.infoApi();
            onCloseHandler();
        } 
    });
    /* ==================== 파트너수정 ==================== */
    /* ==================== 초기데이터 및 이름 ==================== */
    //정비인원selectOption
    const option = [{name:'1명', value:1, disabled:(info.MECHANIC === 1 ? true : false)}, {name:'2명', value:2, disabled:(info.MECHANIC === 2 ? true : false)}, {name:'3명', value:3, disabled:(info.MECHANIC === 3 ? true : false)}, {name:'4명', value:4, disabled:(info.MECHANIC === 4 ? true : false)}, {name:'5명', value:5, disabled:(info.MECHANIC === 5 ? true : false)}, {name:'6명', value:6, disabled:(info.MECHANIC === 6 ? true : false)}, {name:'7명', value:7, disabled:(info.MECHANIC === 7 ? true : false)}, {name:'8명', value:8, disabled:(info.MECHANIC === 8 ? true : false)}, {name:'9명', value:9, disabled:(info.MECHANIC === 9 ? true : false)}, {name:'10명', value:10, disabled:(info.MECHANIC === 10 ? true : false)}];
    //arrayDataFilter
    const dataFilter = (i, type) => {//type - 0:name, 1:defaultValue, 2:disabled여부
        switch(i) {
            case 0 : return type === 0 ? '파트너명' : type === 1 ? info.PARTNER_NAME : type === 2 ? true : null ;
            case 1 : return type === 0 ? '비밀번호' : type === 1 ? '' : type === 2 ? false : null;
            case 2 : return type === 0 ? '연락처' : type === 1 ? (info.TEL_NO ?? '') : type === 2 ? false : null;
            case 3 : return type === 0 ? '이메일주소' : type === 1 ? (info.EMAIL_ADDR ?? '') : type === 2 ? false : null;
            case 4 : return type === 0 ? '주소' : type === 1 ? info.ADDR1 : type === 2 ? false : null;
            case 5 : return type === 0 ? '상세주소' : type === 1 ? (info.ADDR2 ?? '') : type === 2 ? false : null;
            case 6 : return type === 0 ? '정비인원' : type === 1 ? info.MECHANIC : type === 2 ? false : null;
            case 7 : return type === 0 ? '설명' : type === 1 ? (info.SUMMARY ?? '') : type === 2 ? false : null;
        }
    }
    /* ==================== 초기데이터 및 이름 ==================== */

    class NavTabs extends React.Component {
        state = { isDisabled:true, index: 0 };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true, index:value }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                <Element tag={`div`} className="popupContainer">
                    <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>파트너 정보 수정</Element></Element>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className={styles.popupFormArea}>
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={info.IMG_SRC_1} alt={info.PARTNER_NAME} />
                            <Element tag={`div`} className={styles.formContainer}>
                                {[...Array(8)].map((x, i) =>
                                    dataFilter(i, 1) !== undefined ?
                                        i !== 6 ?
                                        <FormWrapper key={i} className={[styles.formArea, dataFilter(i, 2) ? styles.isDisabled : null].join(" ")}><TitleWrapper title={dataFilter(i, 0)} />
                                            <Element tag={`div`} className="textArea">
                                                {i !== 7 ? <input type={i === 1 ? 'password' : 'text'} disabled={dataFilter(i, 2)} defaultValue={dataFilter(i, 1)} ref={(el => partnerUpdateRef.current[i] = el)} />
                                                : <textarea ref={(el => partnerUpdateRef.current[i] = el)} defaultValue={info.SUMMARY} ></textarea> }
                                            </Element>
                                        </FormWrapper>
                                        : <Element tag={`div`} className={["formSelectArea", "themeLight" , "popupFormArea", "upSelectOption", styles.formSelectArea].join(" ")}><TitleWrapper title={dataFilter(i, 0)} />
                                            <SelectSearch options={option} onChange={el => partnerUpdateRef.current[i].value = el} ref={(el => partnerUpdateRef.current[i] = el)} defaultValue={info.MECHANIC} placeholder="정비인원"  />
                                        </Element>
                                    : null
                                )}
                            </Element>
                        </Element>
                    </Element>
                    <Element tag={`div`} className="popupFooter">
                        <Element tag={`div`} className="btnArea">
                            <Button anyprop={(e) => onCloseHandler()} class="closeButton" icon={"fa-light fa-xmark"} label={`닫기`} />
                            <Button anyprop={(e) => partnerUpdateAPI(partnerUpdateRef.current[6].value)} class="submitButton" label={`확인`} />
                        </Element>
                    </Element>
                </Element>
            </Modal>
        )}
    } return ( <NavTabs /> )
}