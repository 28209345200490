import './App.scss';
import 'src/asset/scss/config/_style.scss';
//Comp
import Routers from 'src/webapp/views/Router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

function App() {
  library.add(fat, fal, fas);

  return ( <Routers/> );
}

export default App;
