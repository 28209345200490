import React, { useRef, useState } from 'react';
import styles from 'src/asset/scss/styles/login.module.scss';
import { useNavigate } from 'react-router-dom';
//cookie
import { setCookie } from "src/webapp/config/cookie";
//stores
import useAdminStore from 'src/webapp/stores/admin.store';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Button from 'src/webapp/views/component/Button';

export default function Login() {
    const navigator = useNavigate();//화면이동
    const { axiosPostHandler } = useAxiosHook();//axios
    //stores
    const { setAdmin } = useAdminStore();
    //hook
    const { refreshTokenExpires, accessTokenExpires } = useComnHook();
    //입력창
    const ref = useRef([]);
    //엔터이벤트
    const handleKeydown = (e) => {
        if(e.key === 'Enter') {
          submitHandler();
        }
    };
    //관리자로그인API
    const submitHandler = async() => {
        const DATA = {ADMIN_ID:ref.current[0].value, PASS_WD:ref.current[1].value};
        const result = await axiosPostHandler(`adminLoginForAdmin.do`, DATA);
        if(!result) { alert(); return; }
        if(result.message === "LOGIN_FAILED") { alert("로그인 정보가 바르지 않습니다."); console.log("로그인 정보가 바르지 않습니다."); return; }
        if(result.message === "FORBIDDEN_IP_ADDRESS") { alert("접속이 허용되지 않은 IP입니다.\n관리자에게 문의해주세요."); console.log("접속이 허용되지 않은 IP입니다.\n관리자에게 문의해주세요."); return; }
        if(result.message === "SUCCESS") { 
            const refreshExpires = refreshTokenExpires();
            const accessExpires = accessTokenExpires();
            
            await setCookie("NIDRA", result.data.REFRESH_TOKEN, { expires:refreshExpires, path: "/" });//refreshToken
            await setCookie("NIDAA", result.data.ACCESS_TOKEN, { expires:accessExpires, path: "/" });//accessToken
            
            setAdmin(result.data);
            setTimeout(() => { navigator("/dashboard", { replace: true }); }, 1000);
            return; 
        }
    }
    //입력값검증
    const [ isDisabled, setDisabled ] = useState(null);
    const setValidHandler = () => {
        let valid = true;
        for(let i = 0; i < ref.current.length; i++) {
            const data = ref.current[i] ?? null;
            if(data.value.length <= 0) {
                valid = i; 
                break;
            }
        }
        setDisabled(valid);
    }

    return (
        <Section>
            <div className={styles.loginArea}>
                <h1>로그인을 진행해주세요</h1>         
                <div className={styles.inputArea}>
                    <div className={[(isDisabled === 0 ? styles.invalid : null), styles.inputField].join(" ")}>
                        <label>아이디</label>
                        <input onChange={(e) => setValidHandler()}
                            ref={el => (ref.current[0] = el)}
                            type="text" inputMode='text' />
                        {isDisabled === 0 ? <span>아이디를 입력해주세요.</span> : null}
                    </div>
                    <div className={[(isDisabled === 1 ? styles.invalid : null), styles.inputField].join(" ")}>
                        <label>패스워드</label>
                        <input onChange={(e) => setValidHandler()} onKeyDown={(e) => handleKeydown(e)} 
                            ref={el => (ref.current[1] = el)}
                            type="password" inputMode='password' />
                        {isDisabled === 1 ? <span>비밀번호를 입력해주세요.</span> : null}
                    </div>
                </div>
                <Button disabled={isDisabled === true ? false : true} anyprop={submitHandler} label={'로그인 하기'} class={'submit'} />
            </div>
        </Section>
    )
       
}