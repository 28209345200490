import React, { useEffect, useState } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
//hooks
import useRefreshAccessTokenHook from 'src/webapp/hooks/useRefreshAccessTokenHook';
//Comp
import { Login
    , Dashboard
    , Data
    , MemberList, Member, MemberReservList
    , PartnerList, Partner, PartnerScheduleList, PartnerVehicleReg, PartnerItemInfo, PartnerItemReg, PartnerApproveList
    , BoardList
    , NotifyList, NotifySend 
    , BrandList, BrandItemList 
} from 'src/webapp/views';

export default React.memo(function Router() {
    const location = useLocation();
    const locate = location.pathname;
    const navigator = useNavigate();
    //Hook
    const { adminAuthCheckAPI } = useRefreshAccessTokenHook(locate);//토큰유효성체크
    //state
    const [ isPending, setPending ] = useState(true);
    const [ isState, setState ] = useState(null);
    //useEffect
    useEffect(() => {(async () => {
        setPending(true);
        const result = await adminAuthCheckAPI();
        if(locate !== "/" && !result) { navigator("/", {replace:true}); return; }
        setState(result);
        setPending(false);;
    })();
    }, [locate]);

    const routes = [
        {path: "/", element: !isPending && !isState ? <Login /> : !isPending && isState ? <Dashboard /> : <Login /> },
        , {path:"/dashboard", element:<Dashboard /> }//대시보드
        , {path:"/datas", element:<Data /> }//통계
        , {path: "/user"//회원 및 파트너정보
            , children: [
                , {path:"member/list", element: <MemberList /> }
                , {path:"member/:cd", element: <Member /> }
                , {path:"member/reserv/list", element: <MemberReservList /> }
                , {path:"partner/list", element: <PartnerList /> }
                , {path:"partner/:cd"
                    , children: [
                        , {path:"", element: <Partner /> }
                        , {path:"schedule/list", element: <PartnerScheduleList /> }
                        , {path:"reg/vehicle", element: <PartnerVehicleReg /> }
                        , {path:"item/:item", element: <PartnerItemInfo /> }
                        , {path:"reg/item", element: <PartnerItemReg /> }
                ]}
                , {path:"partner/:cd", element: <Partner /> }
                , {path:"partner/approve/list", element: <PartnerApproveList /> }
        ]}
        , {path: "/board"//공지사항
            , children: [
                , {path:"list", element: <BoardList /> }
        ]}
        , {path: "/notify"//알림
            , children: [
                , {path:"list", element: <NotifyList /> }
                , {path:"send", element: <NotifySend /> }
        ]}
        , {path: "/brand"//차량
            , children: [
                , {path:"item/list", element: <BrandItemList /> }
                , {path:"list", element: <BrandList /> }
        ]}
    ];

    let router = useRoutes(routes);
    return ( router );
})