import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import BrandList from './List';//제조사별차량리스트
import BrandAdd from './popup/BrandAdd';//제조사추가팝업
import BrandUpdate from './popup/BrandUpdate';//제조사추가수정

export default function MemberList() {
    //stores
    const { admin } = useAdminStore();
    //팝업오픈여부
    const [ isOpen,setOpen ] = useState({add:false, update:false});
    const onDismissHandler = (name, value) => {
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    //브랜드선택핸들러
    const parentRef = useRef({});
    //선택브랜드삭제핸들러
    const brandOnDeleteHanelr = () => {
        parentRef.current.brandDeleteAPI();
    }

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`차량 데이터 관리`}  dept={[{label:'기타'}, {label:'차량 데이터 관리'}, {label:'제조사 관리'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <ActiveButtonWrapper>
                        <Element tag={`div`} >
                            <Link to={'/brand/item/list'}><span>차량 관리</span></Link>
                            {admin.LEVEL <= 1 ? <button onClick={(e) => onDismissHandler('add', true)}><span>제조사 추가</span></button> : null}
                        </Element>
                        <Element tag={`div`} >
                            {admin.LEVEL <= 2 ? <button onClick={(e) => brandOnDeleteHanelr()} className="btnDelete"><span>선택 삭제</span></button> : null}
                        </Element>
                    </ActiveButtonWrapper>
                </FixedPanel>
                <FlickPanel>
                    <BrandList ref={parentRef} onDismiss={onDismissHandler} />
                </FlickPanel>
            </Container>
        </Section>
        <BrandAdd isOpen={isOpen.add} onDismiss={onDismissHandler} />
        <BrandUpdate isOpen={isOpen.update} onDismiss={onDismissHandler} />
        </>
    );
}