import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//hooks
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

const _index01 = forwardRef((props, ref) => {
    //props
    const display = props.display;
    //상위컴포넌트에서 호출
    useImperativeHandle(ref, () => ({
        brandListAPI
    }));
    const { pageRef,//무한로딩페이지DOM
        brandList,//브랜드리스트데이터
        brandListAPI//브랜드리스트API
    } = useBrandHook();
    //브랜드선택
    const [ brand, setBrand ] = useState(0);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if(inView && (pageRef.current != null)) { brandListAPI(null, null); }//브랜드리스트API
    }, [inView]);
    useEffect(() => {
        if(display) { if(brand !== 0) {props.onValid(null, false)} }//validateCheck
    }, [display]);

    return ( 
        <div style={{display: display ? 'flex' : 'none'}} className={styles.popupBrandListArea}>
            {brandList.length > 0 ? 
                <>
                <ul>
                    {brandList.map((item) => (
                        <li key={item.BRAND_CD} onClick={(e) => {setBrand(item); props.onValid(item, false);}}
                            className={brand === item ? styles.selected : null}>
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={`brand/logo/gray` + item.IMG_SRC} alt={item.BRAND_KOR_NAME} />
                            <span>{item.BRAND_KOR_NAME}</span>
                        </li>
                    ))}
                </ul>
                {brandList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div> : null}
                </>
            : null }
        </div>
    )
})
export default _index01;