import React from 'react';
import { Link } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerItemInfo.module.scss';
//compWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import Element from 'src/webapp/views/wrapper/Element';
//comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Info from './Info';//아이템상세

export default function PartnerItemInfo() {

    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`상품 상세`} dept={[{ label: '회원관리' }, { label: '파트너스' }, { label: '파트너 정보' }, { label: '상품 상세' }]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FlickPanel>
                    <Info/>
                </FlickPanel>
                <FixedPanel>
                    <Element tag={`div`} className={styles.btnArea}>
                        <Link to={-1}><FontAwesomeIcon icon="fa-light fa-chevron-left" /><Element tag={`span`}>이전으로</Element></Link>
                    </Element>
                </FixedPanel>
            </Container>
        </Section>
    );
}