import React, { useState, useRef } from 'react';

const useUploadFileHook = () => {
    const MAX_COUNT =  useRef(1);
    const [ files, setFiles ] = useState([]);//파일배열
    //파일여러개등록(파일선택 > 확인 > 등록) 
    const addFilesHandler = (e) => {
        const photoToAdd = e.target.files;
        let temp = [...files];
        for (let i = 0; i < photoToAdd.length; i++) {
            if(!photoToAdd[i].type.includes('webp')) {
                alert(`해당 파일 확장자(.webp)만 선택가능합니다.`);
                return;
            } else {
                temp.push({ id: photoToAdd[i].name, file: photoToAdd[i], url: URL.createObjectURL(photoToAdd[i]) })
            }
            //temp.push({ id: photoToAdd[i].name, file: photoToAdd[i], url: URL.createObjectURL(photoToAdd[i]) })
        };
        if (temp.length > MAX_COUNT.current) {
            temp = temp.slice(0, MAX_COUNT.current);
        }
        setFiles(temp);
    };
    //파일등록(파일선택 > 등록) 
    const addFileHandler = async(e) => {
        const photoToAdd = e.target.files;
        let temp = [];
        for (let i = 0; i < photoToAdd.length; i++) {
            if(!photoToAdd[i].type.includes('webp')) {
                alert(`해당 파일 확장자(.webp)만 선택가능합니다.`);
                return;
            } else {
                temp.push({ id: photoToAdd[i].name, file: photoToAdd[i], url: URL.createObjectURL(photoToAdd[i]) })
            }
            //temp.push({ id: photoToAdd[i].name, file: photoToAdd[i], url: URL.createObjectURL(photoToAdd[i]) })
        };
        if (temp.length > 1) {
            temp = temp.slice(0, 1);
        }
        setFiles(temp);
        return temp;
    };
    

    return { files, setFiles, MAX_COUNT, addFilesHandler, addFileHandler };
}

export default useUploadFileHook;