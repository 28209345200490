import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//hooks
import useUploadFileHook from 'src/webapp/hooks/comn/uploadFile.hook';
//Comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목

const _index02 = forwardRef((props, ref) => {
    const { display } = props;
    //props
    // const display = props.display;
    //상위컴포넌트에서 호출
    useImperativeHandle(ref, () => ({
        uploadBrandItemImageAPI
    }));
    //stores    
    const { admin } = useAdminStore();
    const inputRef = props.inputRef;
    const { axiosMultiPartHandler } = useAxiosHook();//axios
    //파일추가
    const { files, setFiles//fileuseState
        , MAX_COUNT//최대업로드갯수
        , addFileHandler//파일업로드핸들러
    } = useUploadFileHook();
    /* ==================== 브랜드모델이미지업로드 ==================== */
    const uploadBrandItemImageAPI = async (imgCd, brandCd) => {
        if(files.length > 0) {
            const DATA = new FormData();
            let variables = { "IMG_CD":imgCd, "BRAND_CD":brandCd, "TYPE":2, "LAST_UPDATE_USER":admin.COMN_ID };
            for (let i = 0; i < files.length; i++) { DATA.append('file', files[i].file); }
            DATA.append('requestDTO', new Blob([JSON.stringify(variables)]), { type: "application/json" });
            const result = await axiosMultiPartHandler(`brandImgRegForJson.do`, DATA);
            if(!result) { return; }
            if(result.message != "SUCCESS") { return; }
            if(result.message == "SUCCESS") { return; }
        }
    }
    /* ==================== 브랜드모델이미지업로드 ==================== */
    /* ==================== validationCheck ==================== */
    const onValidateHandler = () => {
        for(let i = 0; i < inputRef.current.length; i++) {
            if(inputRef.current[i].value.length <= 0) { props.onValid(null, true); return; } 
        }
        if(files.length <= (MAX_COUNT.current - 1)) { props.onValid(null, true); return; }
        props.onValid(null, false);
    }
    useEffect(() => {
        onValidateHandler();
    }, [files]);
    useEffect(() => {
        if(display) { onValidateHandler(); }//validateCheck
    }, [display]);
    /* ==================== validationCheck ==================== */

    return ( 
        <div style={{display:display ? 'flex' : 'none'}} className="popupFormArea">
            <FormWrapper>
                <TitleWrapper title={'차종(모델명) - 한글'}/>
                <div className="textArea">
                    <input onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[0] = el)}
                        type="text" inputMode="text" placeholder="내용을 입력해주세요." />
                </div>
            </FormWrapper>
            <FormWrapper>
                <TitleWrapper title={'차종(모델명) - 영문'}/>
                <div className="textArea">
                    <input onChange={(e) => onValidateHandler()}
                        ref={el => (inputRef.current[1] = el)}
                        type="text" inputMode="text" placeholder="내용을 입력해주세요." />
                </div>
            </FormWrapper>
            <FormWrapper><TitleWrapper title={'차량 이미지'}/>
                {files.length < MAX_COUNT.current ? 
                    <div className="fileArea">
                        <label htmlFor="file"><span>이미지 업로드</span><FontAwesomeIcon icon="fa-light fa-square-dashed-circle-plus" /></label> 
                        <input onChange={(e) => addFileHandler(e)} type="file" id="file" accept='image/webp' />
                    </div>   
                : null}
                {files.length > 0 && files.map((file, idx) => (
                    <div key={file.id} className="imgPreview">
                        <button onClick={(e) => setFiles([])}><FontAwesomeIcon icon="fa-light fa-xmark" /></button>
                        <div className="previewImage"><img src={file.url} alt="preview-img" /></div>
                        <label>{file.id}</label>
                    </div>
                ))}
            </FormWrapper>
        </div>
    );
})
export default _index02;