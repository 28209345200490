import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import SubtitleArea from 'src/webapp/views/wrapper/SubtitleArea';//서브타이틀공통컴포넌트
import List from './reviewList/List';//리뷰리스트

export default function ReviewList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    //업체별리뷰리스트API
    const [reviewList, setReviewList] = useState(null);
    const pageRef = useRef(20);
    const partnerReviewListAPI = async (type) => {//type-0.최초로딩, 1.무한로딩
        const DATA = { PARTNER_CD: params.cd, ROWNUM: (type === 0 ? 20 : pageRef.current) };
        const result = await axiosPostHandler(`partnerReviewListByPartnerForAdmin.do`, DATA);
        if (!result) { return; }
        if (result.message === "INVALID_DATA") { setReviewList([]); }
        if (result.message === "SUCCESS") {
            setReviewList(result.data);
        }
    }
    const [inViewRef, inView] = useInView({ threshold: .3 });
    useEffect(() => {
        if (inView && reviewList === null) { partnerReviewListAPI(0); }
    }, [inView]);

    return (
        <Element propRef={inViewRef} tag={`div`} className={styles.reviewContainer}>
            <SubtitleArea title={'업체리뷰'} cnt={`${reviewList === null ? 0 : reviewList.length}건`} />
            <Element tag={`div`}><List list={reviewList} /></Element>
        </Element>
    );
}