import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/component/button.module.scss';

export default React.memo(function Button(props) {//class - submit:styles.submitButton
    const { label, disabled, icon, anyprop, htmlFor } = props;
    //scss적용
    const style = props.class
    const classNameFilter = () => {
        switch(style) {
            case "submit" : return styles.submitButton;
            case "subtitle" : return styles.subtitleButton;
            default : return style;
        }
    }
    const className = classNameFilter();

    return ( 
        <button onClick={anyprop} disabled={disabled} className={className} htmlFor={htmlFor}>
            {icon ? <FontAwesomeIcon icon={icon} /> : null}
            <span>{label}</span>
        </button>
    );
}) 