import React, { useState } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음

export default function List({list}) {
    //엘리먼트토글
    const [ toggle,setToggle ] = useState({});

    return ( 
        <>
        {list !== null && list.length > 0 ? 
            <Element tag={`ul`}>
                {list.map((item, i) =>
                    <Element tag={`li`} key={item.SEQ_NO}>
                        <Element tag={`div`}>
                            <Element tag={`div`} style={{width: "8.5rem"}}>
                                <Element tag={`label`}>{item.MEMBER_NAME}</Element>
                            </Element><hr/>
                            <Element tag={`div`} style={{width: "11.54rem"}} className={styles.dateTime}>
                                <Element tag={`span`} className={styles.dateTime}><FontAwesomeIcon icon="fa-light fa-clock" />{item.REG_DT}</Element>
                            </Element><hr/>
                            <Element tag={`div`} style={{width: "5.75rem"}} className={styles.starRate}>
                                {[...Array(5)].map((x, i) =>
                                    <FontAwesomeIcon icon={[(i < item.RATE ? "fa-solid" : "fa-light"), "fa-star"].join(" ")} />
                                )}
                            </Element><hr/>
                            <Element tag={`div`}  style={{width: "12.75rem"}}>
                                <Element tag={`span`}>{item.BRAND_KOR_NAME + " " + item.MODEL_KOR_NAME}</Element>
                            </Element>
                            {item.DEL_YN === "N" ? <Element tag={`div`}  className={styles.stretch}></Element>
                            : <><hr/><Element tag={`div`}  className={styles.stretch}><Element tag={`h5`}>삭제</Element></Element></>}
                            <Element tag={`div`}  style={{width: "52px"}}>
                                {item.DEL_YN === "N" ? <Element tag={`button`}><FontAwesomeIcon icon="fa-light fa-trash-can" /><Element tag={`span`}>삭제</Element></Element> 
                                : <Element tag={`button`}><FontAwesomeIcon icon="fa-light fa-trash-can-undo" /><Element tag={`span`}>취소</Element></Element>}
                            </Element>
                            <a className={item.REVIEW === undefined ? styles.none : null} onClick={(e) => setToggle(toggle === item ? {} : item.REVIEW !== undefined ? item : toggle)}>
                                <FontAwesomeIcon icon={["fa-light", toggle === item ? "fa-chevron-up": "fa-chevron-down"].join(" ")} />
                            </a>
                        </Element>
                        {toggle === item ? 
                        <Element tag={`article`}>
                            <pre dangerouslySetInnerHTML={{__html: item.REVIEW}}></pre>
                        </Element>
                        : null}
                    </Element>
                )}
            </Element>
        : <Element tag={`div`} className={styles.emptyData}><EmptyData/></Element> }
        </>
    );
}