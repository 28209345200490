import React, { useEffect, useState } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
//comp
import PartnerReservRankingChild from './PartnerReservRankingChild';//업체랭킹

export default function PartnerReservRanking(props) {
    const { axiosPostHandler } = useAxiosHook();//axios

    /* ==================== 차량등록상위TOP10 ==================== */
    const [ topRankList,setTopRankList ] = useState(null);
    const [ bottomRankList,setBottomRankList ] = useState(null);
    const memberVehicleRankAPI = async(sort) => {
        const DATA = { SORT:sort }
        const result = await axiosPostHandler(`partnerReservRankForAdmin.do`, DATA);
        if(!result) { return; }
        let data = [];
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { data = result.data; } 
        if(sort === "DESC") { setTopRankList(data); }//상위TOP3
        if(sort === "ASC") { setBottomRankList(data); }//하위TOP3
    }
    /* ==================== 차량등록상위TOP10 ==================== */
    const [ inViewRef, inView ] = useInView({ threshold:.3 });
    useEffect(() => {
        if(inView && topRankList === null) { memberVehicleRankAPI("DESC"); }//차량등록상위TOP10API
        if(inView && bottomRankList === null) { memberVehicleRankAPI("ASC"); }//차량등록하위TOP10API
    }, [inView]);

    return ( 
        <Element tag={`div`} propRef={inViewRef} className={styles.partnerReservContainer}>
            <Element tag={`div`} className={styles.rankContainer}>
                <Element tag={`h1`}>예약 상위 파트너</Element>    
                <Element tag={`div`} className={styles.rankArea}>
                    {topRankList !== null && topRankList.length > 0 && topRankList.map((item, i) => 
                        <PartnerReservRankingChild key={i} index={i} item={item} />
                    )}
                    {topRankList !== null && topRankList.length <= 0 ? <EmptyData small={true} /> : null}
                </Element>
            </Element>
            <Element tag={`div`} className={styles.rankContainer}>
                <Element tag={`h1`}>예약 하위 파트너</Element>    
                <Element tag={`div`} className={styles.rankArea}>
                    {bottomRankList !== null && bottomRankList.length > 0 && bottomRankList.map((item, i) => 
                        <PartnerReservRankingChild key={i} index={i} item={item} />
                    )}
                    {bottomRankList !== null && bottomRankList.length <= 0 ? <EmptyData small={true} /> : null}
                    {/* {[...Array(3)].map((x, i) =>
                        <PartnerReservRankingChild index={i} />
                    )} */}
                </Element>
            </Element>
        </Element>
    );
}