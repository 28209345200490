import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//차량모델이미지
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import ItemToggle from './ItemToggle';//toggleSwitch
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지
//Hook
import useBrandHook from 'src/webapp/hooks/brand/brand.hook';

const ItemList = forwardRef((props, ref) => {
    //Hook
    const { pageRef, modelList, checkList, brandItemListAPI } = useBrandHook();
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        brandItemOnLoadHandler(item) {
            brandRef.current = item;
            brandItemListAPI(0, brandRef.current.BRAND_CD);
        }
        , brandItemDeleteAPI
    }));
    const { axiosPostHandler } = useAxiosHook();//axios
    //브랜드별차량리스트
    //제조사별모델리스트API
    const brandRef = useRef(null);
    // const pageRef = useRef(0);
    // const checkList = useRef([]);//수락 또는 거절 체크리스트
    // const [ modelList, setModelList ] = useState([]);
    // const brandItemListAPI = (async (type) => {//type - 0:최초로딩, 1:무한로딩
    //     pageRef.current = (type === 0 ? 0 : pageRef.current);
    //     if(type === 0) { checkList.current = []; }
    //     const DATA = { "BRAND_CD":brandRef.current.BRAND_CD, "ROWNUM":pageRef.current };
    //     const result = await axiosPostHandler(`brandItemListForAdmin.do`, DATA);
    //     if(!result) { return; }
    //     if(result.message != "SUCCESS") { pageRef.current = null; }
    //     if(result.message == "SUCCESS") { 
    //         setModelList(pageRef.current === 0 ? result.data : modelList.concat(result.data));
    //         pageRef.current = (result.data.length >= 20 ? (pageRef.current + 1)  : null);
    //     } 
    // });
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { brandItemListAPI(1, brandRef.current.BRAND_CD); }//알림리스트API
    }, [inView]);

    const parentRef = useRef({});//메세지컴포넌트ref
    /* ==================== 차량모델삭제 ==================== */
    const brandItemDeleteAPI = async() => {
        let DATA = [];
        for(let i = 0; i < checkList.current.length; i++) {
            if(checkList.current[i] != null) {
                const obj = JSON.parse(checkList.current[i].value);
                const MODEL_CD = obj.MODEL_CD;
                if(checkList.current[i].checked) {
                    const obj = { MODEL_CD:MODEL_CD };
                    DATA.push(obj);
                }
            }
        }
        if(DATA.length <= 0) { parentRef.current.setMessageHandler(3, '1개 이상 데이터를 선택해주세요.'); return; } 
        
        if(!window.confirm("데이터 삭제 후 복구가 어려울 수 있습니다.\n해당데이터를 삭제하시겠습니까?")) { return; }
        const result = await axiosPostHandler(`brandItemDeleteForJson.do`, DATA);
        if(!result) { parentRef.current.setMessageHandler(2, '일시적인 오류가 발생했습니다.'); }
        if(result.message === "SUCCESS") { 
            parentRef.current.setMessageHandler(1, '삭제가 완료되었습니다.'); 
            brandItemListAPI(0, brandRef.current.BRAND_CD); 
        }
    }
    /* ==================== 차량모델삭제 ==================== */
 
	return (
        <>
        <div className={styles.brandItemListArea}>
            {modelList.length > 0 ? 
                <ul>
                    {modelList.map((item, i) => 
                        <li key={item.MODEL_CD}>
                            <input type='checkbox' ref={el => (checkList.current[i] = el)} value={JSON.stringify(item)} />
                            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.BRAND_KOR_NAME} />  
                            <div style={{width: "13rem"}}>
                                <div><label>{item.MODEL_KOR_NAME}</label><span>{item.MODEL_ENG_NAME}</span></div>
                            </div><hr/>
                            <div style={{width: "5.5rem"}}><label>{item.INFO_1}</label></div><hr/>
                            <div className={styles.stretch}><label>{item.INFO_2 !== null && item.INFO_2 !== '-' ? (item.INFO_2 + 'cc') : '-'}</label></div>
                            <div><button onClick={(e) => props.onDismiss('update', true)}><FontAwesomeIcon icon="fa-light fa-pen-to-square" />수정하기</button></div>
                            <div className={styles.toggleArea}><ItemToggle item={item}  /></div>
                        </li>
                    )}
                </ul>
            : <EmptyData />}
            {modelList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
            : modelList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
            : null}
        </div>
        <SubmitPopup ref={parentRef} />
        </>
	)
})
export default ItemList;