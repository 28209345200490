import React from 'react';
import { Link, useParams } from 'react-router-dom';
//modalPopup
import Modal from 'react-modal';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerItemReg.module.scss';
//hooks
import useUploadFileHook from 'src/webapp/hooks/comn/uploadFile.hook';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//입력폼
import TitleWrapper from 'src/webapp/views/wrapper/form/Title';//입력폼내제목
//comp
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import VehicleListPopup from '../popup/VehicleList';//팝업-등록차량선택

export default function Form() {
    //파일추가
    const { files, setFiles//fileuseState
        , MAX_COUNT//최대업로드갯수
        , addFileHandler//파일업로드핸들러
    } = useUploadFileHook();

    class Form extends React.Component {
        state = { index:null };
        // state = { disabled: true, index: null };
        handleChange = (e, value) => {
            this.setState({ ...this.state, index:value });
        };
        
        render() {
            const { index, disabled } = this.state;
            
            return (
                <>
                <Element tag={`div`} className={styles.formContainer}>
                    <Element tag={`div`} className={styles.formArea}>
                        {/* START 이미지 추가 */}
                        {files.length < MAX_COUNT.current ? 
                            <Element tag={`div`} className={styles.fileArea}>
                                <Element tag={`label`} htmlFor="file"><Element tag={`span`}>이미지 업로드</Element><FontAwesomeIcon icon="fa-light fa-rectangle-history-circle-plus" /></Element> 
                                <input onChange={(e) => addFileHandler(e)} type="file" id="file" accept='image/webp' />
                            </Element> 
                        : null}
                        {files.length > 0 && files.map((file, idx) => (
                            <Element tag={`div`} className={styles.imgPreview}>
                                <Element tag={`div`} className={styles.previewImage}><img src={file.url} alt="preview-img" /></Element>
                                <Button anyprop={(e) => setFiles([])} icon={`fa-light fa-pen-to-square`} label={`이미지 변경하기`} />
                            </Element>
                        ))}
                        {/* END 이미지 추가 */}
                        {/* START 사용차량추가 */}
                        <FormWrapper>
                            <TitleWrapper title={'사용 차량 :'}/>
                            <Element tag={`ul`}>
                                {[...Array(12)].map((x, i) =>
                                    <Element tag={`li`} key={i}><Element tag={`label`}>125 ET 4-125 ET 4</Element><Button icon={"fa-light fa-xmark"}/></Element>
                                )}
                            </Element>
                            <Button icon={"fa-light fa-circle-plus"} label={`사용 차량 추가하기`} />
                        </FormWrapper>
                        {/* END 사용차량추가 */}
                        {/* START 입력필드 */}
                        {[...Array(7)].map((x, i) =>
                            <FormWrapper key={i}>
                                <TitleWrapper title={'제품명'}/>
                                <input type="text" />
                            </FormWrapper>
                        )}
                        {/* END 입력필드 */}
                    </Element>
                    {/* START 버튼 */}
                    <Element tag={`div`} className={styles.btnArea}>
                        <Link to={-1}><FontAwesomeIcon icon="fa-light fa-chevron-left" /><Element tag={`span`}>이전으로</Element></Link>
                        <Button icon={"fa-light fa-floppy-disk"} label={`등록하기`} />
                    </Element>
                    {/* END 버튼 */}
                </Element>
                <VehicleListPopup isOpen={true} />{/* 사용차량등록팝업 */}
                </>
            )
        }
    } return (<Form />)
}