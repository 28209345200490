import React from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import MemberReservCount from './children/MemberReservCount';//회원이용건수
import RegTotal from './children/RegTotal';//이용자통계
import MemberVehicleRanking from './children/MemberVehicleRanking';//회원등록차량통계
import PartnerReservRanking from './children/PartnerReservRanking';//업체예약통계

export default function Data() {

    //infinityObserver
    // const [ inViewForPartnerReservRankRef, inViewForPartnerReservRank ] = useInView({ threshold: .3 });//리뷰컴포넌트

    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`통계`} dept={[{label:'기타'}, {label:'통계'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FlickPanel>
                    <Element tag={`div`} className={styles.datasContainer}>
                        <MemberReservCount />
                        <RegTotal />
                        <MemberVehicleRanking />
                        <PartnerReservRanking />
                    </Element>
                </FlickPanel>
            </Container>
        </Section>
    );
}