import React from 'react';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//메뉴
import ImgArea from 'src/webapp/views/component/ImgArea';//브랜드로고이미지

export default function PartnerReservRankingChild({index, item}) {
    
    return ( 
        <Element tag={`article`}>
            <Element tag={`h2`}>{index + 1}.</Element>    
            <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.PARTNER_NAME} />
            <Element tag={`div`}>
                <Element tag={`div`}>
                    <Element tag={`em`}>{item.CNT}건</Element>    
                    <Element tag={`span`}>{item.PARTNER_NAME}</Element>    
                </Element>    
                <Element tag={`div`}>
                    <Element tag={`label`}>{item.ADDR1}</Element>    
                    <Element tag={`label`}>{item.ADDR2 ?? ' '}</Element>    
                </Element>    
            </Element>
        </Element>
    );
}