import React, { useEffect } from 'react';
//inview
import { useInView } from 'react-intersection-observer';
//hooks
import useReservHook from 'src/webapp/hooks/reserv/reserv.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Search from './Search';//검색
import List from './List';//스케줄리스트
// import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function PartnerList() {
    
    const {pageRef,//무한로딩페이지DOM
        searchRef,//검색어DOM
        reservList,//브랜드리스트데이터
        reservListAPI//브랜드리스트API
    } = useReservHook();
    useEffect(() => {
        reservListAPI(1, 2);//예약목록API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { reservListAPI(0, 2); }//예약목록API
    }, [inView]);

    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`파트너스 예약`} dept={[{label:'회원관리'}, {label:'파트너스'}, {label:'파트너스 정보'}, {label:'스케줄 보기'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <Search searchRef={searchRef} onSearch={reservListAPI} />
                </FixedPanel>
                <FlickPanel>
                    <List list={reservList} />
                    {/* 
                    {partnerList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
                    : partnerList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
                    : null} */}
                </FlickPanel>
            </Container>
        </Section>
    );
}