import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useUploadFileHook from 'src/webapp/hooks/comn/uploadFile.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//comp
import ComnTitle from '../../component/ComnTitle';
import ImgArea from 'src/webapp/views/component/ImgArea';
import ImagePopup from './popup/Image';//이미지팝업

export default function ImgList(props) {
    const { info,setInfo } = props;
    //stores    
    const { admin } = useAdminStore();
    const params = useParams();//queryParameter
    const { axiosMultiPartHandler } = useAxiosHook();//axios

    const { addFileHandler } = useUploadFileHook();
    //이미지파일업로드API
    const uploadPartnerImageAPI = async (e, type) => {
        const message = type === 1 ? '외관' : type === 2 ? '사업자등록증' : null;
        if(!window.confirm(message + " 이미지를 변경하시겠습니까?")) { return; }

        const files = await addFileHandler(e);
        if(files.length > 0) {
            const DATA = new FormData();
            const variables = { PARTNER_CD:params.cd, TYPE:type, LAST_UPDATE_USER:admin.COMN_ID };
            for (let i = 0; i < files.length; i++) { DATA.append('file', files[i].file); }
            DATA.append('requestDTO', new Blob([JSON.stringify(variables)]), { type: "application/json" });
            const result = await axiosMultiPartHandler(`partnerCertImgRegForAdmin.do`, DATA);
            if(!result) { return; }
            if(result.message != "SUCCESS") { }
            if(result.message == "SUCCESS") { 
                let name = 'IMG_SRC_' + type;
                setInfo({ ...info, [name]:files[0].url});
                props.onMessage(1, info.PARTNER_NAME + "님의 " + message + " 정보가 변경되었습니다.");
                e.target.value = null;
            }
        }
    }

    //상세내역오픈여부
    const [ isOpen,setOpen ] = useState({popup:false});
    //팝업오픈여부
    const [ item,setItem ] = useState(null);
    const onDismissHandler = (name, value, item) => {//type - 1.외관이미지, 2.사업자등록증
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        });
        setItem(value ? item : null);
    }

    return ( 
        <>
        <div className={styles.imgContainer}>
            <ComnTitle title={'업체 이미지'}/>
            <ul>
                {[...Array(2)].map((x, i) =>
                    <li key={i}>
                        <label>{i === 0 ? '외관 이미지' : i === 1 ? '사업자등록증' : null}</label>
                        <ImgArea key={i} anyprop={(e) => onDismissHandler('popup', true, info)} className={["imgArea", styles.imgArea].join(" ")} src={i === 0 ? info.IMG_SRC_1 : i === 1 ? info.IMG_SRC_2 : null} alt={info.PARTNER_NAME} />
                        <div className={styles.fileArea}>
                            <label className={styles.updateButton} htmlFor={"file" + i}><span>변경하기</span></label> 
                            <input onChange={(e) => uploadPartnerImageAPI(e, (i + 1))} type="file" id={"file" + i} /> 
                            {/* 
                            <label className={styles.deleteButton} htmlFor="file"><span>삭제</span></label> 
                            <input onChange={(e) => { uploadPartnerImageAPI(e, 2); }}
                                type="file" id="file" />
                             */}
                        </div> 
                    </li>
                )}
            </ul>
        </div>
        <ImagePopup item={item} isOpen={isOpen.popup} onDismiss={onDismissHandler}  />
        </>
    );
}