import React from 'react';

export default React.memo(function ImgArea(props) {
    const {children, className, anyprop, root, src} = props;
    const onErrorImg = (e) => {
        e.target.src = src;
    }

    return ( 
        <>
        <div className={className} onClick={anyprop}>
            <img src={root ? src : `${process.env.REACT_APP_IMAGE_URL + src}`} onError={onErrorImg} alt={props.alt} />
        </div>
        </>
    );
})