import React from 'react';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';

function SubtitleArea(props) {
    const {children, className, style, tag, cnt, title} = props;

    return  <div className={styles.titleArea}>
                <div><h1>{title}{cnt ? <em>{cnt}</em> : null}</h1>
                    <div>{children}</div>
                </div>
            </div>
 }
  
 export default SubtitleArea;