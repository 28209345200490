import { create } from "zustand";

const useStore = create((set) => ({
    member: {},//회원정보 - 회원코드, 회원아이디
    admin: {},//로그인 - 관리자정보
    isState: false,//회원로그인상태
    message:{type:null, label:null},

    setMember: (member) => set((state) => ({ ...state, member })),
    setAdmin: (admin) => set((state) => ({ ...state, admin})),
    setIsState: (isState) => set((state) => ({ ...state, isState })),
    setMessage: (message) => set((state) => ({ ...state, message })),
}));

export default useStore;
