import React from 'react';
//compWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
//comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Form from './child/Form';//입력폼

export default function PartnerItemReg() {

    return (
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`차량 등록`} dept={[{ label: '회원관리' }, { label: '파트너스' }, { label: '파트너 정보' }, { label: '상품 등록' }]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FlickPanel>
                        <Form />
                </FlickPanel>
            </Container>
        </Section>
    )
}