import React from 'react';
//modalPopup
import Modal from 'react-modal';
//slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
import styles from 'src/asset/scss/styles/userPartnersApprove.module.scss';
//comp
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지

export default function Image(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
            height: "100%"
        }
    };
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('popup', false);
    }
    //슬라이드Styles
    const slickSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    //팝업데이터
    const item = props.item;
    
    class Image extends React.Component {
        state = { index:0 };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                {item !== null ? 
                    <div className="popupContainer">
                        <div className="popupHeader"><h1>{item.PARTNER_NAME}</h1></div>
                        <div className="popupContainer">
                            <Slider {...slickSettings}>
                                {[...Array(2)].map((x, i) =>
                                    <div key={i} className={styles.popupImgContainer}>
                                        <ImgArea className={["imgArea", styles.imgArea].join(" ")} 
                                            src={i === 0 ? item.IMG_SRC_1 : i === 1 ? item.IMG_SRC_2 : null} 
                                            alt={item.PARTNER_NAME} />
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <div className="popupFooter">
                            <div className="btnArea">
                                <button onClick={(e) => onCloseHandler()} className="submitButton"><span>닫기</span></button>
                            </div>
                        </div>
                    </div>
                : null}
            </Modal>
        )}
    } return ( <Image /> )
}