import React from 'react';
//styles
import styles from 'src/asset/scss/styles/brandItemList.module.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function _stepper(props) {
    const index = props.index;

    return ( 
        <div className={styles.stepperArea}>
            <span className={index === 0 ? styles.selected : null}>제조사 선택</span>
            <FontAwesomeIcon icon="fa-light fa-chevron-right" />
            <span className={index === 1 ? styles.selected : null}>차량 정보 입력</span>
            <FontAwesomeIcon icon="fa-light fa-chevron-right" />
            <span className={index === 2 ? styles.selected : null}>제원 정보 입력</span>
        </div>
    );
}