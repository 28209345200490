import React from 'react';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';

export default React.memo(function ComnTitle(props) {
    
    return ( 
        <div className={styles.titleArea}>
            <div><h1>{props.title}{props.cnt ? <em>{props.cnt}</em> : null}</h1>
                <div><button></button></div>
            </div>
        </div>
    );
})