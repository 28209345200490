import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/dashboard.module.scss';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import TotalCount from './TotalCount';//당일집계
import ReservList from './ReservList';//당일예약목록

export default function Search(props) {

    return ( 
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`대시보드`} dept={[]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <TotalCount />
                </FixedPanel>
                <FlickPanel>
                    <ReservList />
                </FlickPanel>
            </Container>
        </Section>
    );
}