import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/dashboard.module.scss';

export default function TotalCount() {
    const { axiosPostHandler } = useAxiosHook();//axios
    //대시보드통계데이터
    const [ dashboard, setDashboard ] = useState({});
    //대시보드통계API
    const dashboardDataAPI = async() => {
        const result = await axiosPostHandler(`dashboardCountForAdmin.do`, {});
        if(!result) { return; }
        if(result.message === "SUCCESS") { setDashboard(result.data); return; }
    }
    useEffect(() => {
        dashboardDataAPI();//대시보드통계API
    }, []);

    return ( 
        <div className={styles.countArea}>
            <div><h1>예약</h1><h2>{dashboard.RESERV_CNT}건</h2></div>
            <div><h1>정비완료</h1><h2>{dashboard.SUCCESSED_CNT}건</h2></div>
            <div className={styles.payment}><h1>결제 금액</h1><h2>총 {dashboard.RESERV_TOT_PRICE}원</h2></div>
            <div>
                <h1>가입 요청</h1>
                <span>
                    <h3>{dashboard.AWAIT_CNT}건</h3>
                    <Link to={'/user/partner/approve/list'}><FontAwesomeIcon icon="fa-light fa-chevron-right" /></Link>
                </span>
            </div>
        </div>
    );
}