import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
// import styles from 'src/asset/scss/styles/userPartnerList.module.scss';
import styles from 'src/asset/scss/component/searchField.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div

export default function Search(props) {
    const { searchRef } = props;
    //searchData
    const today = new Date().toISOString().slice(0, 10);//오늘날짜셋팅

    return ( 
        <Element tag={`div`} className={styles.searchArea}>
            <Element tag={`div`} className={styles.dateField}>
                <input type="date" onChange={(e) => props.onSearch(1, 2)}
                    ref={el => (searchRef.current[0] = el)}
                    defaultValue={today} />
                <Element tag={`span`}>-</Element>
                <input type="date" onChange={(e) => props.onSearch(1, 2)} 
                    ref={el => (searchRef.current[1] = el)}
                    defaultValue={today} />
            </Element>
        </Element>
    );
}