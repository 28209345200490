import React, { useEffect, useState, useRef } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import Element from 'src/webapp/views/wrapper/Element';
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단메뉴바
import List from './boardList/List';//공지사항리스트
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function BoardList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    //stores
    const { admin } = useAdminStore();
    /* ==================== 공지사항리스트 ==================== */
    //공지사항리스트API
    const [ boardList,setBoardList ] = useState([]); 
    const isLock = useRef(false);
    const pageRef = useRef(20);
    const boardListAPI = async(type) => {//type- 0.최초로딩, 1.무한로딩, 3.등록후로딩
        if((type === 0 && pageRef.current == null) || isLock.current) { return; }
        isLock.current = true;
        pageRef.current = (type === 0 || type === 3 ? 20 : pageRef.current);

        const DATA = { ROWNUM:pageRef.current };
        const result = await axiosPostHandler(`boardNoticeListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setBoardList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setBoardList(type === 0 || type === 3 ? result.data : boardList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
        }
    }
    useEffect(() => {
        boardListAPI(0);//공지사항리스트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { boardListAPI(1); }//공지사항리스트API
    }, [inView]);
    /* ==================== 공지사항리스트 ==================== */
    /* ==================== 공지사항등록 ==================== */
    //하위컴포넌트함수호출
    const childRef = useRef({});//공지리스트
    const addPopupOpenHandler = () => {
        childRef.current.addPopupOpenHandler();
    }
    /* ==================== 공지사항등록 ==================== */

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`공지사항`} dept={[{label:'공지사항'}, {label:'공지 목록'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                {admin.LEVEL <= 1 ? 
                    <FixedPanel>
                        <ActiveButtonWrapper>
                            <Element tag={`div`}></Element>
                            <Element tag={`div`}>
                                <Button anyprop={(e) => addPopupOpenHandler()} label={`등록하기`} />
                            </Element>
                        </ActiveButtonWrapper>
                    </FixedPanel>
                : null}
                <FlickPanel>
                    <List ref={childRef} list={boardList} setBoardList={setBoardList} onLoad={boardListAPI} />
                    {boardList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
                    : pageRef.current === null ? <InViewData readAll={true} /> 
                    : null}
                </FlickPanel>
            </Container>
        </Section>
        
        </>
    );
}