import React, { useEffect, useState, useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/notify.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import FormWrapper from 'src/webapp/views/wrapper/form/Form';//메뉴

export default function Form({receiverListRef}) {
    //const { inputRef } = props;
    const { axiosPostHandler } = useAxiosHook();//axios
    //selectOption
    const option = [{name:'회원', value:'3'}, {name:'파트너스', value:'4', disabled:true}];
    const receiverRef = useRef(3);
    
    /* ==================== 메세지수신인 검색 ==================== */
    const isLock = useRef(false);
    const inputRef = useRef('');
    const [ fcmList,setFcmList ] = useState([]);
    const fcmTokenListAPI = (async (e) => {
        const value = e.target.value;
        if(isLock.current || value.length < 2) { if(value.length < 2 && fcmList.length > 0) { setFcmList([]); } return; }
        
        const DATA = { MEMBER_NAME:value, RECEIVER:receiverRef.current.value ?? 3 };
        const result = await axiosPostHandler(`fcmTokenListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(!result) { return; }
        if(result.message != "SUCCESS") { setFcmList([]); }
        if(result.message == "SUCCESS") { 
            setFcmList(result.data !== undefined ? result.data : []);
        } 
    });
    //검색필터리셋
    const resetSearchListHandler = () => {
        setFcmList([]);
        inputRef.current.value = null;
    }
    /* ==================== 메세지수신인 검색 ==================== */
    /* ==================== 메세지수신인 추가 및 삭제 ==================== */
    const [ receiverList,setReceiverList ] = useState([]);//수신자리스트(*지정 수신일 경우)
    //메세지수신인추가
    const addReceiverHandler = (newItem) => {
        if(!receiverList.some((item) => item.MEMBER_CD === newItem.MEMBER_CD)) {
            setReceiverList([...receiverList, newItem]);  
            //console.log(receiverListRef.current);
        }
    }
    //메세지수신인삭제
    const removeReceiverHandler = (revmoeItem) => {
        const newPeople = receiverList.filter((item) => item !== revmoeItem);
        setReceiverList(newPeople);
    }
    useEffect(() => {
        receiverListRef.current = receiverList;
    }, [receiverList]);
    /* ==================== 메세지수신인 추가 및 삭제 ==================== */

    return ( 
        <>
        <FormWrapper>
            <div className={styles.checkoutArea}>
                <div className="formSelectArea">
                    <SelectSearch options={option} defaultValue={option[0]?.value}
                        ref={el => (receiverRef.current = el)} onChange={el => {receiverRef.current.value = el}}
                        isOptionDisabled={(option) => option.disabled}
                        placeholder="지정수신자"  />
                </div>
                <div className={[styles.textField, styles.searchList].join(" ")}>
                    <input onChange={(e) => fcmTokenListAPI(e)} 
                        ref={el => (inputRef.current = el)}
                        type="search" placeholder="발송할 대상을 입력해주세요" />
                    {fcmList.length > 0 ?
                        <div>
                            <button onClick={(e) => resetSearchListHandler()}>닫기</button>
                            <ul>
                            {fcmList.map((item) => 
                                <li key={item.MEMBER_CD} onClick={(e) => addReceiverHandler(item)}><span>{item.MEMBER_NAME}</span></li> 
                            )}
                            </ul>
                        </div>
                    : null}
                </div>
            </div>
        </FormWrapper>
        <FormWrapper>
            <div className={styles.listArea}>
                <ul>
                    {receiverList.length > 0 && receiverList.map((item) => 
                        <li key={item.MEMBER_CD} onClick={(e) => removeReceiverHandler(item)}><span>{item.MEMBER_NAME}</span><button><FontAwesomeIcon icon="fa-light fa-xmark" /></button></li>
                    )}
                </ul>
            </div>
        </FormWrapper>
        </>
    );
}