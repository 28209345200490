import React from "react";
//cookie
import { getCookie, removeCookie, setCookie } from 'src/webapp/config/cookie';
//axios
import useAxiosHook from "src/webapp/hooks/comn/axios.hook";
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//hookss
import useComnHook from "src/webapp/hooks/comn/comn.hook";

const useRefreshAccessTokenHook = () => {
    //stores
    const { setAdmin, setIsState } = useAdminStore();
    //hooks
    const { axiosPostHandler } = useAxiosHook();
    const { accessTokenExpires } = useComnHook();//토큰 만료기한 설정
    
    //토큰유효성체크
    const adminAuthCheckAPI = async() => {
        const NIDAA = getCookie("NIDAA");
        const NIDRA = getCookie("NIDRA") ?? null;
        if(NIDRA === undefined || NIDRA == null) { return false; }

        const DATA = { "REFRESH_TOKEN":NIDRA, "ACCESS_TOKEN":NIDAA ?? null };
        const result = await axiosPostHandler(`adminAuthCheckForAdmin.do`, DATA);
        if(!result) { return result; }
        let rval = true;
        if(result.message == "AUTH_REQUEST_CREATE_SUCCESS") {
            const accessExpires = accessTokenExpires();
            setCookie("NIDAA", result.data.ACCESS_TOKEN, { expires:accessExpires, path:"/" });
        }
        if(result.message == "AUTH_REQUEST_FAILED") {
            rval = false;
            removeCookie('NIDAA');
            removeCookie('NIDRA');
        }
        if(result.message == "AUTH_REQUEST_SUCCESS" || result.message == "AUTH_REQUEST_CREATE_SUCCESS") {}
        setAdmin(!rval ? {} : result.data);
        setIsState(rval);

        return rval;
    };

    return { adminAuthCheckAPI//로그인유효성확인
    };
}

export default useRefreshAccessTokenHook;
