import React, { useEffect, useState, useRef } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//style
import styles from 'src/asset/scss/styles/notify.module.scss';
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//리스트데이터none
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function List(props) {

    const { axiosPostHandler } = useAxiosHook();//axios

    /* ==================== 메세지전송로그데이터API ==================== */
    //메세지전송로그리스트API
    const pageRef = useRef(20);//페이지DOM
    const isLock = useRef(false);//더블클릭방지DOM
    const [ logList,setLogList ] = useState([]);
    const sendMessageLogListAPI = async(type) => {//type- 0.최초로딩, 1.무한로딩, 3.등록후로딩
        if((type === 0 && pageRef.current == null) || isLock.current) { return; }

        isLock.current = true;
        pageRef.current = (type === 0 || type === 3 ? 20 : pageRef.current);
        const DATA = { ROWNUM:pageRef.current };
        const result = await axiosPostHandler(`sendMessageLogListForAdmin.do`, DATA);
        setTimeout(() => { isLock.current = false; }, 500);
        if(result.message === "INVALID_DATA") { 
            if(pageRef.current === 20) { setLogList([]); }
            pageRef.current = null;
        }
        if(result.message === "SUCCESS") { 
            setLogList(type === 0 || type === 3 ? result.data : logList.concat(result.data));
            pageRef.current = result.data.length < 20 ? null : pageRef.current + 20;
        }
    }
    useEffect(() => {
        sendMessageLogListAPI(0);//메세지전송로그리스트API
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { sendMessageLogListAPI(1); }//메세지전송로그리스트API
    }, [inView]);
    /* ==================== 메세지전송로그데이터API ==================== */
    /* ==================== 데이터상세보기 ==================== */
    const [ toggle,setToggle ] = useState(null);
    /* ==================== 데이터상세보기 ==================== */
    /* ==================== 샘플데이터출력 ==================== */
    //날짜포맷(yyyy.mm.dd)
    function formatDateHandler(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}.${month}.${day}`;
    }
    const itemOnClickHandler = (item) => {
        const REG_DT = formatDateHandler(new Date()) === item.REG_DT.split(' ')[0] ? item.REG_DT.split(' ')[2] : item.REG_DT.split(' ')[0];
        const DATA = {subject:item.SUBJECT, content:item.CONTENT, type:item.TYPE, regTime:REG_DT}
        props.onSample(DATA);
    }
    /* ==================== 샘플데이터출력 ==================== */

    return ( 
        <>
        <div className={styles.notifyListArea}>
            {logList.length > 0 ? 
            <>
            <ul>
                {logList.map((item, i) => 
                <li key={item.SEQ_NO}>
                    <div>
                        <div className={styles.title}>
                            <label onClick={(e) => itemOnClickHandler(item)}>{item.SUBJECT}</label>
                            <a onClick={(e) => setToggle(toggle === item ? {} : item)}><FontAwesomeIcon icon={["fa-light", toggle === item ? "fa-chevron-up": "fa-chevron-down"].join(" ")} /></a>
                        </div>
                        <div className={styles.info}>
                            <span><FontAwesomeIcon icon="fa-light fa-calendar" />{item.REG_DT}</span>
                            <span>{item.RECEIVE_TYPE === 1 ? 'FCM' : item.RECEIVE_TYPE === 2 ? 'APP' : null}</span>
                            <span>{item.RECEIVER === 1 ? '멤버스' : item.RECEIVER === 2 ? '파트너스' : item.RECEIVER === 3 ? ' 지정수신자' : null}</span>
                        </div>
                    </div>
                    {toggle === item ? 
                    <article>
                        <fieldset>{item.CONTENT}</fieldset>
                    </article>
                    : null}
                </li>     
                )}       
            </ul>
            {logList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
            : pageRef.current === null ? <InViewData readAll={true} /> 
            : null}
            </>
            : <EmptyData/> }
        </div>
        
        </>
    );
}