import React, { useEffect, useState } from 'react';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import MemberVehicleRankingPieChart from './MemberVehicleRankingPieChart';//브랜드랭킹
import MemberVehicleRankingChild from './MemberVehicleRankingChild';//모델별랭킹

export default function MemberVehicleRanking() {
    const { axiosPostHandler } = useAxiosHook();//axios

    /* ==================== 차량등록브랜드상위TOP7 ==================== */
    const [ vehicleBrandRank,setVehicleBrandRank ] = useState(null);
    const memberVehicleBrandRankAPI = async() => {
        const result = await axiosPostHandler(`memberVehicleBrandRankForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { setVehicleBrandRank([]); }
        if(result.message == "SUCCESS") { 
            setVehicleBrandRank(result.data);
        } 
    }
    /* ==================== 차량등록브랜드상위TOP7 ==================== */
    /* ==================== 차량등록모델상위TOP10 ==================== */
    const [ vehicleModelRank,setVehicleModelRank ] = useState(null);
    const memberVehicleModelRankAPI = async() => {
        const result = await axiosPostHandler(`memberVehicleModelRankForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            setVehicleModelRank(result.data);
        } 
    }
    /* ==================== 차량등록모델상위TOP10 ==================== */
    const [ inViewRef, inView ] = useInView({ threshold:.3 });
    useEffect(() => {
        if(inView && vehicleBrandRank === null) { memberVehicleBrandRankAPI(); }//차량등록브랜드상위TOP7API
        if(inView && vehicleModelRank === null) { memberVehicleModelRankAPI(); }//차량등록모델상위TOP10API
    }, [inView]);

    return ( 
            <Element tag={`div`} propRef={inViewRef} className={styles.memberVehicleContainer}>
                <Element tag={`div`} className={styles.pieChart}>
                    <Element tag={`h1`}>제조사별 건수 비교</Element>  
                    {vehicleBrandRank !== null ? <MemberVehicleRankingPieChart data={vehicleBrandRank} /> : null }
                </Element>
                <Element tag={`div`} className={styles.modelRank}>
                    <Element tag={`h1`}>차량 등록 순위</Element>    
                    <Element tag={`div`} className={styles.rankArea}>
                        {vehicleModelRank !== null && vehicleModelRank.length > 0 && vehicleModelRank.map((item, i) => 
                            <MemberVehicleRankingChild key={item.MODEL_KOR_NAME} index={i} item={item} />
                        )}
                        {/* {[...Array(10)].map((x, i) =>
                            <MemberVehicleRankingChild index={i} />
                        )} */}
                    </Element>
                </Element>
            </Element>
    );
}