import React, { useEffect, useState } from 'react';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import RegTotalUserLineChart from './RegTotalUserLineChart';//회원 - 월별가입추이
import RegTotalUser from './RegTotalUser';//회원 & 업체 - 가입자추이

export default function RegTotal() {
    const { axiosPostHandler } = useAxiosHook();//axios

    /* ==================== 내달회원가입통계 ==================== */
    const [ memberStats,setMemberStats ] = useState({});
    const memberRegStatsAPI = async() => {
        const result = await axiosPostHandler(`memberRegStatsForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            setMemberStats(result.data);
        } 
    }
    /* ==================== 내달회원가입통계 ==================== */
    /* ==================== 내달업체가입통계 ==================== */
    const [ partnerStats,setPartnerStats ] = useState({});
    const partnerRegStatsAPI = async() => {
        const result = await axiosPostHandler(`partnerRegStatsForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            setPartnerStats(result.data);
        } 
    }
    /* ==================== 내달업체가입통계 ==================== */
    /* ==================== 회원가입추이 ==================== */
    const [ memberTendency,setMemberTendency ] = useState({});
    const memberRegTendencyAPI = async() => {
        const result = await axiosPostHandler(`memberRegTendencyForAdmin.do`, {});
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            setMemberTendency(result.data);
        } 
    }
    /* ==================== 회원가입추이 ==================== */

    useEffect(() => {(async () => {
        memberRegStatsAPI();//내달회원가입통계API
        partnerRegStatsAPI();//내달업체가입통계API
        memberRegTendencyAPI();//회원가입추이API
    })();
    }, []);

    return ( 
        <Element tag={`div`} className={styles.regDataContainer}>
            <Element tag={`div`} className={styles.lineChart}>
                <Element tag={`div`} className={styles.titleArea}>
                    <Element tag={`h1`}>이용자 & 신규 가입자</Element>
                    <Element tag={`div`}>
                        <Element tag={`span`}>이용자</Element>
                        <Element tag={`span`} className={styles.green}>신규 가입자</Element>
                    </Element>
                </Element>
                <RegTotalUserLineChart data={memberTendency} />
            </Element>
            <Element tag={`div`} className={styles.totCnt}>
                <RegTotalUser data={memberStats} type={'member'} />
                <RegTotalUser data={partnerStats} type={'partner'} />
            </Element>
        </Element>
    );
}