import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//horizontalScroll
import ScrollContainer from 'react-indiana-drag-scroll';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
import buttonStyles from 'src/asset/scss/component/button.module.scss';
//store
import useStore from 'src/webapp/stores/admin.store';
//comp
import Element from 'src/webapp/views/wrapper/Element';//기본엘리먼트
import SubtitleArea from 'src/webapp/views/wrapper/SubtitleArea';//서브타이틀공통컴포넌트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음

export default function Vehicle(props) {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    //Store
    const { admin } = useStore();

    /* ==================== 파트너등록차량리스트 ==================== */
    //등록차량리스트API
    const [ vehicleList, setVehicleList ] = useState({ data:[], size:0 });
    const partnerVehicleListAPI = async () => {
        const DATA = { PARTNER_CD: params.cd };
        const result = await axiosPostHandler(`partnerVehicleListByPartnerForAdmin.do`, DATA);
        if (!result) { return; }
        if (result.message === "INVALID_DATA") { setVehicleList({ data:[], size:0 }); }
        if (result.message === "SUCCESS") {
            vehicleListGroup(result.data, 1);//브랜드별그룹화함수
        }
    }
    useEffect(() => {
        partnerVehicleListAPI();//등록차량리스트API
    }, []);
    /* ==================== 파트너등록차량리스트 ==================== */
    /* ==================== 파트너등록차량삭제 ==================== */
    //선택차량삭제API
    const isLock = useRef(false);
    const [ deletesVehicles,setDeletesVehicles ] = useState([]);
    const VehicleDeleteAPI = async () => {
        if(isLock.current || !window.confirm("등록된 차량을 삭제한 후에는 다시 복구할 수 없습니다.\n삭제하시겠습니까?")) { return; }
        isLock.current = true;
        let DATA = [];
        let newList = [];
        for(let i = 0; deletesVehicles.length > i; i++) {
            if(deletesVehicles[i].DEL_YN !== 'Y') {
                DATA.push({ PARTNER_CD:params.cd, MODEL_CD:deletesVehicles[i].MODEL_CD, LAST_UPDATE_USER:admin.COMN_ID });
            }
        }
        const result = await axiosPostHandler(`partnerVehicleDeleteForAdmin.do`, DATA);
        setTimeout(function() { isLock.current = false; }, 300);
        if(!result) { return; }
        if(result.message != "SUCCESS") { props.onMessage(2, '차량삭제에 실패하였습니다.'); }
        if(result.message == "SUCCESS") { 
            props.onMessage(1, '차량이 삭제되었습니다.');
            setDeletesVehicles([]); 
            /* == 차량삭제정보업데이트 == */
            vehicleList.data.filter((item) => { 
                for(let s = 0; s < item.brandGroup.length; s++) {
                    if(deletesVehicles.includes(item.brandGroup[s])) {
                        item.brandGroup[s]['DEL_YN'] = 'Y';
                    }
                    newList.push(item.brandGroup[s]); 
                }
            });
            vehicleListGroup(newList, 2);
            /* == 차량삭제정보업데이트 == */
        }
    }
    /* ==================== 파트너등록차량삭제 ==================== */
    /* ==================== 파트너등록차량 및 삭제 그룹화 함수 ==================== */
    //브랜드별그룹화함수
    const vehicleListGroup = (data, type) => {
        const array = data, hash = Object.create(null), grouped = [];
        array.forEach(function (o) {
            const key = ['BRAND_CD'].map(function (k) { return o[k]; }).join('|');
            if (!hash[key]) {
                hash[key] = { brandGroup: [] };
                grouped.push(hash[key]);
            }
            hash[key]['brandGroup'].push(o);
            // const row = { BRAND_CD:o['BRAND_CD'], BRAND_KOR_NAME:o['BRAND_KOR_NAME'], MODEL_CD:o['MODEL_CD'], MODEL_KOR_NAME:o['MODEL_KOR_NAME'] };
            // hash[key]['brandGroup'].push(row) 
        });
        if(type === 1) { setVehicleList({ data:grouped, size:data.length }); }
        if(type === 2) { setVehicleList((prevState) => { return { ...prevState, [data]:grouped }; }); }
    }
    /* ==================== 파트너등록차량 및 삭제 그룹화 함수 ==================== */

    return (
        <Element tag={`div`} className={styles.vehicleContainer}>
            <SubtitleArea title={'등록차량'} >
                {admin.LEVEL <= 1 ? <Button anyprop={(e) => VehicleDeleteAPI()} class={"subtitle"} icon="fa-light fa-trash-can" label={"선택삭제"} ></Button> : null}
                {admin.LEVEL <= 2 || admin.LEVEL === 9 ? <Link to={`/user/partner/${params.cd}/reg/vehicle`} className={buttonStyles.subtitleButton}><FontAwesomeIcon icon="fa-light fa-circle-plus" /><Element tag={`span`}>등록하기</Element></Link> : null}
            </SubtitleArea>
            {vehicleList.data.length > 0 ?
                <Element tag={`div`} className={styles.vehicleListArea}>
                    {vehicleList.data.map((group, i) =>
                        <ScrollContainer horizontal={true} component={`ul`} key={i}>
                            {group.brandGroup.map((item, i) =>
                                <Element tag={`li`} key={item.MODEL_CD} anyprop={(e) => deletesVehicles.includes(item) ? setDeletesVehicles(deletesVehicles.filter((item) => !item)) : setDeletesVehicles([...deletesVehicles, item])}>
                                    <input type='checkbox'
                                        disabled={item.DEL_YN === 'Y' ? true : false} 
                                        checked={deletesVehicles.includes(item)} 
                                        onChange={(e) => e.target.checked ? (deletesVehicles.includes(item) ? null : setDeletesVehicles([...deletesVehicles, item])) : (deletesVehicles.includes(item) ? setDeletesVehicles(deletesVehicles.filter((item) => !item)) : null)} />
                                    <Element tag={`label`}>{item.BRAND_KOR_NAME + ' ' + item.MODEL_KOR_NAME}</Element>
                                    {item.DEL_YN === "Y" || item.USE_YN === "N" ? <Element tag={`em`}>{item.DEL_YN === "Y" ? "삭제" : item.USE_YN === "N" ? "비활성" : null}</Element> : null}
                                    {/* <Element tag={`button`}><FontAwesomeIcon icon="fa-light fa-chevron-right" /></Element> */}
                                </Element>
                            )}
                        </ScrollContainer> 
                    )}
                </Element>
                : <Element tag={`div`} className={styles.emptyData}><EmptyData /></Element>
            }
        </Element>
    );
}