import React from 'react';
//styles
import styles from 'src/asset/scss/styles/userPartnerVehicleReg.module.scss';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import Element from 'src/webapp/views/wrapper/Element';
//comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import BrandList from './BrandList';//브랜드리스트
import ModelList from './ModelList';//모델리스트

export default function PartnerVehicleReg() {

    class PartnerVehicleReg extends React.Component {
        state = { index:null };
        // state = { disabled: true, index: null };
        handleChange = (e, value) => {
            this.setState({ ...this.state, index:value });
        };
        
        render() {
            const { index, disabled } = this.state;
            return (
                <Section>
                    <Menu />{/* 메뉴 */}
                    <Container>
                        <FixedPanel>
                            <Titlebar title={`차량 등록`} dept={[{ label: '회원관리' }, { label: '파트너스' }, { label: '파트너 정보' }, { label: '차량 등록' }]} />{/* 상단타이틀 */}
                        </FixedPanel>
                        <FixedPanel>
                            <Element tag={`div`} className={styles.brandContainer}>
                                <Element tag={`h1`}>제조사 선택하기</Element>
                                <BrandList choose={this.handleChange} />
                            </Element>
                        </FixedPanel>
                        <FlickPanel>
                            <Element tag={`div`} className={styles.modelContainer}>
                                <Element tag={`h1`}>차종 선택</Element>
                                <ModelList brand={index} />
                            </Element>
                        </FlickPanel>
                    </Container>
                </Section>
            )
        }
    } return (<PartnerVehicleReg />)
}