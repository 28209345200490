import React, { PureComponent, useEffect, useState } from 'react';
//chart
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } from "recharts";
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//CompWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트

export default function RegTotalUserLineChart({data}) {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <Element tag={`div`} className={styles.tooltipArea}>
                <Element tag={`div`}><Element tag={`span`}>이용자</Element><Element tag={`label`}>{new Intl.NumberFormat('en').format(payload[0].value)}명</Element></Element>
                <Element tag={`div`}><Element tag={`span`}>신규 가입자</Element><Element tag={`label`}>{new Intl.NumberFormat('en').format(payload[1].value)}명</Element></Element>
            </Element>
          );
        }
        return null;
    };

    /* ==================== 차트YAxis넓이동적적용 ==================== */
    const [ width,setWidth ] = useState(0);
    useEffect(() => {(async () => {
        if(data.length > 0) {
            const label = data.map((item) => {
                return item.tot;
            }).reduce((acc, cur) => {
                return cur.toString().length > acc.toString().length ? cur : acc;
            }, {})
            setWidth((label.toString().length + 2) * 3);
        }
    })();
    }, [data]);
    /* ==================== 차트YAxis넓이동적적용 ==================== */

    class RegTotalUserLineChart extends PureComponent {
        render() {
        return (
            <Element tag={`div`} className={styles.lineChartArea}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart width={"100%"} height={312} data={data}>
                        <defs>
                            <linearGradient id="colorTot" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="12.7%" stopColor="#FFF" stopOpacity={.1}/>
                                <stop offset="99%" stopColor="#191A1A" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorNew" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="12.7%" stopColor="#00BC29" stopOpacity={.1}/>
                                <stop offset="99%" stopColor="#191A1A" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis stroke="#A1A1A1" fontSize={12} tickMargin={10} tickLine={false} unit="월" dataKey="name" />
                        <YAxis stroke="#A1A1A1" fontSize={12} tickMargin={10} tickLine={false} width={width} tickFormatter={(value) => new Intl.NumberFormat("en-US", { compactDisplay: "short" }).format(value)} />
                        <CartesianGrid stroke="#373737" vertical={false} />
                        <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#FFF", strokeWidth: 1, strokeDasharray: "5 5", strokeOpacity: .8 }} />
                        <Area type="monotone" dataKey="tot" stroke="#FFFFFF" strokeWidth={2} fillOpacity={1} fill="url(#colorTot)" name="이용자" />
                        <Area type="monotone" dataKey="curr" stroke="#00BC29" strokeWidth={2} fillOpacity={1} fill="url(#colorNew)" name="신규 가입자" />
                    </AreaChart>
                </ResponsiveContainer>
            </Element>
        );}
    } return ( <RegTotalUserLineChart /> )
}
