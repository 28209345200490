import React, { useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
import Button from 'src/webapp/views/component/Button';//버튼엘리먼트
//comp

export default function VehicleList(props) {
    //cssStyles
    const popupStyles = {
        content: {
            width: "33.125rem",
        }
    };
    const { axiosPostHandler } = useAxiosHook();//axios
    //stores
    const { admin } = useAdminStore();
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = (type) => {
        props.onDismiss('update', false, type);
    }
    //입력Ref
    const inputRef = useRef([]);//브랜드기본정보
    //공지사항데이터 
    const data = props.data;

    /* ==================== 공지사항수정 ==================== */
    const boardUpdateAPI = async() => {
        const TYPE = inputRef.current[2].value !== 'on' ? inputRef.current[2].value : 1;
        const DATA = { SEQ_NO:data.current.SEQ_NO, SUBJECT:inputRef.current[0].value, CONTENT:inputRef.current[1].value, TYPE:TYPE, LAST_UPDATE_USER:admin.COMN_ID };
        const result = await axiosPostHandler(`boardNoticeUpdateForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "SUCCESS") { 
            data.current.SUBJECT = DATA.SUBJECT;
            data.current.CONTENT = DATA.CONTENT;
            data.current.TYPE = DATA.TYPE;
            onCloseHandler(1); 
        }
    }
    /* ==================== 공지사항수정 ==================== */

    class BoardUpdate extends React.Component {
        state = { isDisabled:true };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true }); 
        };
        onValidItem = (item, value) => {
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                <Element tag={`div`} className="popupContainer">
                    <Element tag={`div`} className="popupHeader"><Element tag={`h1`}>사용 차량 추가</Element></Element>
                    <Element tag={`div`} className="popupContainer">
                        <Element tag={`div`} className="popupFormArea">
                            
                        </Element>
                    </Element>
                    <Element tag={`div`} className="popupFooter">
                        <Element tag={`div`} className="btnArea">
                            <Button anyprop={(e) => onCloseHandler(2)} class="secondaryButton" label={"취소"} ></Button>
                            <Button anyprop={(e) => boardUpdateAPI()} disabled={this.state.isDisabled} class="submitButton" label={"등록하기"} ></Button>
                        </Element>
                    </Element>
                </Element>
            </Modal>
        )}
    } return ( <BoardUpdate /> )
}