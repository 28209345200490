import React, { useRef } from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerSchedule.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음

export default function List({list}) {
    const { stateFilter } = useComnHook();//상태필터

    return ( 
        <>
        {list.length > 0 ? 
            <Element tag={`div`} className={styles.listArea}>
                <ul>
                    {list.map((item, i) => 
                    <li>
                        <Element tag={`div`}><FontAwesomeIcon icon="fa-light fa-calendar" /><span style={{width: "9.94rem"}}>{item.RESERV_DT}</span></Element><hr />
                        <Element tag={`div`}><span style={{width: "3.24rem"}}>{item.MEMBER_NAME}</span></Element><hr />
                        <Element tag={`div`}><span style={{width: "7.5rem"}}>{item.RESERV_ITEM}</span></Element><hr />
                        <Element tag={`div`}><span style={{width: "9.24rem"}}>{item.MODEL_KOR_NAME}</span></Element><hr />
                        <Element tag={`div`} className={[styles.stretch, ('state' + item.STATE)].join(" ")}><h5>{stateFilter(item.STATE)}</h5></Element>
                    </li>
                    )}
                </ul> 
            </Element>
        : <EmptyData /> }
        </>
    );
}