import React, { useRef } from 'react';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
//Comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Info from './Info';//고객정보
import VehicleList from './VehicleList';//고객정보 - 등록차량리스트
import ReservList from './ReservList';//고객정보 - 예약내역
//import ReviewList from './ReviewList';//업체정보-리뷰리스트
//comp
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지

export default function Member() {
    const { onMessageHandler, messageRef } = useComnHook();//메세지처리
    /* ==================== 차량카운트변경 ==================== */
    const childRef = useRef({});
    //차량카운트변경(차량리스트컴포넌트 -> 회원정보컴포넌트)
    const onChangeVehicleCnt = (cnt) => {
        childRef.current.memberVehicleCntChangeHandler(cnt);
    }
    /* ==================== 차량카운트변경 ==================== */

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`고객 정보`}  dept={[{label:'회원관리'}, {label:'멤버스'}, {label:'고객 정보'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <Info onMessage={onMessageHandler} ref={childRef} />
                </FixedPanel>
                <FlickPanel className={styles.memberFlickPanel}>
                        <VehicleList onMessage={onMessageHandler} onChange={onChangeVehicleCnt} />
                        <ReservList onMessage={onMessageHandler} />
                </FlickPanel>
            </Container>
        </Section>
        <SubmitPopup ref={messageRef} />
        </>
    );
}