import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/component/isEmpty.module.scss';

export default React.memo(function EmptyData({small}) {

    return ( 
        <div className={[styles.emptyArea, small ? styles.small : null].join(" ")}>
            <FontAwesomeIcon icon="fa-solid fa-file" />
            <h5>조회 결과가 없어요</h5>
            <p>입력한 정보가 올바른지 확인해주세요.</p>
        </div>
    );
})