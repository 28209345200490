import React from 'react';
 
function FlickPanel({children, className}) {
   const style = {
      flexGrow: 1,
      overflowY: 'auto'
   };
 
   return <div className={["flickPanel", className].join(" ")} style={style}>{children}</div>
}
 
export default FlickPanel;