import React, { useEffect, useRef } from 'react';
//modalPopup
import Modal from 'react-modal';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import 'src/asset/scss/plugin/modalPopup.scss';
//Comp
import _stepper from './_stepper';//현재단계
import _index01 from './_index01';//차량선택
import _index02 from './_index02';//차량명&이미지입력
import _index03 from './_index03';//제원정보입력

export default function ItemAdd(props) {
    //cssStyles
    const popupStyles = {
        content: {
          width: "33.125rem",
          height: "100%"
        }
    };
    const { axiosPostHandler } = useAxiosHook();//axios
    //팝업오픈핸들러
    const isOpen = props.isOpen;    
    const onCloseHandler = () => {
        props.onDismiss('add', false);
    }
    //브랜드선택핸들러
    const childRef = useRef();
    useEffect(() => {
        if(isOpen) { 
            setTimeout(() => { childRef.current.brandListAPI(null, null); }, 100);
        }//브랜드리스트API
    }, [isOpen]);
    //브랜드차량정보
    const brandRef = useRef([]);//아이템기본정보
    const itemRef = useRef([]);//아이템기본정보
    const itemInfoRef = useRef([]);//아이템제원정보
    //차량정보등록API
    const parentRef = useRef({});
    const onSubmitHandler = async() => {
        const DATA = { "BRAND_CD":brandRef.current.BRAND_CD, "MODEL_KOR_NAME":itemRef.current[0].value, "MODEL_ENG_NAME":itemRef.current[1].value,
            "INFO_1":itemInfoRef.current[0].value, "INFO_2":itemInfoRef.current[1].value, "INFO_3":itemInfoRef.current[2].value, "INFO_4":itemInfoRef.current[3].value, "INFO_5":itemInfoRef.current[4].value, "INFO_6":itemInfoRef.current[5].value, "INFO_7":itemInfoRef.current[6].value, "INFO_8":itemInfoRef.current[7].value, "INFO_9":itemInfoRef.current[8].value, "INFO_10":itemInfoRef.current[9].value, "INFO_11":itemInfoRef.current[10].value, "INFO_12":itemInfoRef.current[11].value, "INFO_13":itemInfoRef.current[12].value, "INFO_14":itemInfoRef.current[13].value, "INFO_15":itemInfoRef.current[14].value, "INFO_16":itemInfoRef.current[15].value,
            "LAST_UPDATE_USER":'system'
        };
        const result = await axiosPostHandler(`brandItemRegForJson.do`, DATA);
        if(!result) { return; }
        if(result.message != "SUCCESS") { }
        if(result.message == "SUCCESS") { 
            const resultImage = await parentRef.current.uploadBrandItemImageAPI(result.data.result, brandRef.current.BRAND_CD);
            setTimeout(() => { onCloseHandler() }, 500);
        } 
    }

    class NavTabs extends React.Component {
        state = { isDisabled:true, index: 0 };
        handleChange = (e, value) => { 
            //this.setState({  ...this.state, index:value }); 
            this.setState({ isDisabled:true, index:value }); 
        };
        onValidItem = (item, value) => {
            if(item != null) { brandRef.current = item; }
            if(this.state.isDisabled != value) {
                this.setState({...this.state, isDisabled:value });
            }
        };
        render() { const { index } = this.state;

        return ( 
            <Modal isOpen={isOpen} onRequestClose={null} style={popupStyles}>
                <div className="popupContainer">
                    <div className="popupHeader"><h1>차량 추가</h1>
                        <_stepper index={index} />
                    </div>
                    <div className="popupContainer">
                        <div>
                            <_index01 display={index === 0 ? true:false} ref={childRef} brand={brandRef} onValid={this.onValidItem} /> 
                            <_index02 display={index === 1 ? true:false} ref={parentRef} inputRef={itemRef} onValid={this.onValidItem} /> 
                            <_index03 display={index === 2 ? true:false} inputRef={itemInfoRef} onValid={this.onValidItem} /> 
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="btnArea">
                            <button onClick={(e) => onCloseHandler()} className="closeButton"><FontAwesomeIcon icon="fa-light fa-xmark" /><span>닫기</span></button>
                            {index > 0 ? 
                            <button onClick={(e) => this.handleChange(e, (index - 1))} 
                                className="secondaryButton"><span>이전</span></button> : null}
                            <button disabled={this.state.isDisabled} onClick={(e) => index === 2 ? onSubmitHandler() : this.handleChange(e, (index + 1))} 
                                className="submitButton"><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )}
    } return ( <NavTabs /> )
}