import React from 'react';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/datas.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//메뉴

export default function RegTotalUser({data, type}) {

    return ( 
        <Element tag={`div`}>
            <Element tag={`h1`}>{type === 'member' ? '총 회원수' : type === 'partner' ? '총 파트너수' : null}</Element>
            <Element tag={`h2`}>{data.TOT_CNT}</Element>
            <Element tag={`div`} className={data.RATE > 0 ? styles.up : data.RATE < 0 ? styles.down : null}>
                <Element tag={`label`}>지난 달 보다</Element>
                {data.RATE !== 0 ? <FontAwesomeIcon icon={["fa-light", data.RATE > 0 ? "fa-arrow-up" : "fa-arrow-down"].join(" ")} /> : null}
                <Element tag={`span`}>{data.RATE}%</Element>
                <Element tag={`span`}>{data.CNT}명</Element>
            </Element>
        </Element>
    );
}