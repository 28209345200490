import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//infinity-observer
import { useInView } from 'react-intersection-observer';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/dashboard.module.scss';
//hooks
//import useMemberHook from 'src/webapp/hooks/member/member.hook';
import useReservHook from 'src/webapp/hooks/reserv/reserv.hook';
//comp
import List from './List';
import InViewData from 'src/webapp/views/component/data/InViewData';//데이터로드확인

export default function ReservList(props) {

    const {pageRef,//무한로딩페이지DOM
        reservList,//브랜드리스트데이터
        reservListAPI//브랜드리스트API
    } = useReservHook();
    useEffect(() => {
        reservListAPI(1, 0);//예약내역(당일~당일)
    }, []);
    //infinityObserver
    const [ inViewRef, inView ] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (pageRef.current != null)) { reservListAPI(0, 0); }//알림리스트API
    }, [inView]);

    return ( 
        <div className={styles.reservContainer}>
            <Link to={'/user/member/reserv/list'}>
                <h1 className={styles.title}><span>예약목록</span><FontAwesomeIcon icon="fa-light fa-chevron-right" /></h1>
            </Link>
            <List list={reservList} />
            {reservList.length > 0 && pageRef.current != null ? <div ref={inViewRef}><InViewData inView={true} /></div>
            : reservList.length > 0 && pageRef.current === null ? <InViewData readAll={true} /> 
            : null}
        </div>
    );
}