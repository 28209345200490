import axios from "axios";

const useAxiosHook = () => {
    //Method-GET
    const axiosGetHandler = async(url, DATA) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_HOST + url}`);
            const res = response.data;
            if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다."); return false; }
            if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB에러가 발생했습니다."); return false; }
            if(res.message != "INVALID_REQUEST" && res.message != "FAILED_DB_PROCESSING") { return res; }
        } catch(error) { console.log("Error >>" + error); return false; }
    }

    //Method-POST
    const axiosPostHandler = async(url, DATA) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACK_HOST + url}`, DATA);
            const res = response.data;
            if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다."); return false; }
            if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB에러가 발생했습니다."); return false; }
            if(res.message != "INVALID_REQUEST" && res.message != "FAILED_DB_PROCESSING") { return res; }
        } catch(error) { console.log("Error >>" + error); return false; }
    }

    //MultipartFile
    const axiosMultiPartHandler = async(url, DATA) => {
        try {
            const HEADER = { headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' } };
            const response = await axios.post(`${process.env.REACT_APP_BACK_HOST + url}`, DATA, HEADER);
            const res = response.data;
            if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); return false; }
            if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다."); return false; }
            if(res.message == "INVALID_DATA") { console.log("데이터가 잘못 되었습니다"); return false; }
            if(res.message != "INVALID_REQUEST" && res.message != "FAILED_DB_PROCESSING" && res.message != "INVALID_DATA") { return res; }
        } catch (error) { console.log("ERROR >>" + error); return false; }
    }

    return { axiosGetHandler, axiosPostHandler, axiosMultiPartHandler };
}

export default useAxiosHook;