import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//axios
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//hooks
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//CompWrapper
import Section from 'src/webapp/views/wrapper/Section';
import Container from 'src/webapp/views/wrapper/Container';
import FixedPanel from 'src/webapp/views/wrapper/FixedPanel';
import FlickPanel from 'src/webapp/views/wrapper/FlickPanel';
import ActiveButtonWrapper from 'src/webapp/views/wrapper/ActiveButtonWrapper';
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import Menu from 'src/webapp/views/component/Menu';//메뉴
import Titlebar from 'src/webapp/views/component/Titlebar';//상단타이틀
import Info from './Info';//업체정보
import ImgList from './ImgList';//업체정보-이미지
import VehicleList from './VehicleList';//업체정보-등록차량리스트
import ItemList from './ItemList';//업체정보-상품리스트
import ReviewList from './ReviewList';//업체정보-리뷰리스트
import SubmitPopup from 'src/webapp/views/component/SubmitPopup';//submit & error & notice 메세지

export default function PartnerList() {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    const { onMessageHandler, messageRef } = useComnHook();//메세지처리
    //회원예약내역리스트API
    const [ info,setInfo ] = useState({});
    const partnerInfoAPI = async() => {
        const DATA = { PARTNER_CD:params.cd };
        const result = await axiosPostHandler(`partnerMasterInfoForAdmin.do`, DATA);
        if(!result) { return; }
        if(result.message === "INVALID_DATA") { }
        if(result.message === "SUCCESS") { 
            setInfo(result.data);
        }
    }
    useEffect(() => {
        partnerInfoAPI();//회원예약내역리스트API
    }, []);

    return ( 
        <>
        <Section>
            <Menu />{/* 메뉴 */}
            <Container>
                <FixedPanel>
                    <Titlebar title={`파트너스 정보`}  dept={[{label:'회원관리'}, {label:'파트너스'}, {label:'파트너 정보'}]} />{/* 상단타이틀 */}
                </FixedPanel>
                <FixedPanel>
                    <ActiveButtonWrapper>
                        <Element tag={`div`}>
                            <Link to={`/user/partner/${params.cd}/schedule/list`} className={"linkedButton"}><FontAwesomeIcon icon="fa-light fa-calendar" /><span>스케줄보기</span></Link>
                        </Element>
                    </ActiveButtonWrapper>
                </FixedPanel>
                <FixedPanel>
                    <Info info={info} infoApi={partnerInfoAPI} />
                </FixedPanel>
                <FlickPanel>
                    <ImgList info={info} setInfo={setInfo} onMessage={onMessageHandler} />
                    <VehicleList onMessage={onMessageHandler} />
                    <div><ItemList onMessage={onMessageHandler} /></div>
                    <div><ReviewList /></div>
                </FlickPanel>
            </Container>
        </Section>
        <SubmitPopup ref={messageRef} />
        </>
    );
}