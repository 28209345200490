import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//horizontalScroll
import ScrollContainer from 'react-indiana-drag-scroll';
//styles
import styles from 'src/asset/scss/styles/userPartnerInfo.module.scss';
//comp
import Element from 'src/webapp/views/wrapper/Element';//기본엘리먼트
import EmptyData from 'src/webapp/views/component/data/EmptyData';//데이터없음
import ImgArea from 'src/webapp/views/component/ImgArea';//이미지컴포넌트
import PartnerItemExport from '../popup/PartnerItemExport';//아이템복사하기팝업

const List = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({//상위컴포넌트에서 호출
        onDismissHandler
    }));
    //props
    let { list, category } = props;
    const navigator = useNavigate();//화면이동
    const params = useParams();//파라미터
    //카테고리필터
    const itemCategoryFilter = (element) => {
        switch (element) {
            case 1: return "엔진오일";
            case 2: return "타이어";
            case 3: return "정기점검";
            case 4: return "기타";
        }
    }
    category = itemCategoryFilter(props.category);//아이템카테고리
    /* ==================== 아이템선택(체크박스) ==================== */
    const [ checkedItems,setCheckedItems ] = useState([]);
    const onCheckedHandler = (e, item) => {
        const isChecked = e.target.checked;
        if(isChecked){
            setCheckedItems([...checkedItems, item]);
        } else{
            setCheckedItems(checkedItems.filter( (el) => el !== item));
        }
        console.log(checkedItems);
    }
    /* ==================== 아이템선택(체크박스) ==================== */
    /* ==================== 팝업오픈여부 ==================== */
    const [ isOpen,setOpen ] = useState({export:false});
    const onDismissHandler = (name, value) => {
        if(value && checkedItems.length <= 0) { alert("복사할 상품을 선택해주세요."); return; }
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ==================== 팝업오픈여부 ==================== */

    return (
        <>
        <Element tag={`div`} className={styles.subtitleArea}>
            <Element tag={`div`}>
                <Element tag={`h1`}>{category}<Element tag={`em`}>{list === null ? 0 : list.length}건</Element></Element>
            </Element>
        </Element>
        {list !== null ?
            <ScrollContainer horizontal={true} component={`ul`}>
                {list.map((item, i) =>
                    <Element tag={`li`} key={i}>
                        <Element tag={`div`} className={styles.itemState}>
                            <input type='checkbox' onClick={(e) => onCheckedHandler(e, item)} />{item.DEL_YN === 'Y' ? <Element tag={`span`}>삭제</Element> : null}
                        </Element>
                        <Element tag={`div`} anyprop={(e) => navigator(`/user/partner/${params.cd}/item/${item.ITEM_CD}`, { replace:false })}>
                            {item.IMG_SRC === undefined || item.IMG_SRC === null ? <Element tag={`div`} className={styles.imgNoneArea}><Element tag={`span`}>이미지없음</Element></Element> : <ImgArea className={["imgArea", styles.imgArea].join(" ")} src={item.IMG_SRC} alt={item.ITEM_NAME} />}
                            <Element tag={`h1`}>{item.ITEM_NAME}</Element>
                            <Element tag={`h2`}>{item.DESCRIPTION}</Element>
                            <Element tag={`h3`}>{item.TOT_PRICE}원</Element>
                        </Element>
                    </Element>
                )}
            </ScrollContainer>
        : <Element tag={`div`} className={styles.emptyData}><EmptyData /></Element>}
        {isOpen.export ? <PartnerItemExport onMessage={props.onMessage} isOpen={isOpen.export} items={checkedItems} onDismiss={onDismissHandler} /> : null}
        </>
    )
})
export default List;