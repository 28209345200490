import React, { forwardRef, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
//stores
import useAdminStore from "src/webapp/stores/admin.store";
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//hook
import useAxiosHook from 'src/webapp/hooks/comn/axios.hook';
//styles
import styles from 'src/asset/scss/styles/userMemberInfo.module.scss';
//compWrapper
import Element from 'src/webapp/views/wrapper/Element';//공통엘리먼트
//comp
import EmptyData from 'src/webapp/views/component/data/EmptyData';
import PaymentCancelPopup from '../popup/PaymentCancel';//결제취소팝업

const VehicleList = forwardRef((props, pageRef) => {
    const { axiosPostHandler } = useAxiosHook();//axios
    const params = useParams();//queryParameter
    const { list } = props;
    //stores
    const { admin } = useAdminStore();

    //예약카테고리필터
    const itemCategoryFilter = (element) => {
        switch(element) {
            case "1" : return "엔진오일";
            case "2" : return "타이어";
            case "3" : return "정기점검";
            case "4" : return "기타";
        }
    }
    //예약상태필터
    const itemStateFilter = (element) => {//예약상태- 1.예약진행중, 2.예약완료, 3.입고완료, 4.점검완료, 5.이력등록완료, 6.수락지연취소, 7.미입고취소, 8.취소완료
        switch(element) {
            case "1" : return "예약대기중";
            case "2" : return "예약완료";
            case "3" : return "입고완료";
            case "4" : return "점검완료";
            case "5" : return "이력등록완료";
            case "6" : return "수락지연취소";
            case "7" : return "미입고취소";
            case "8" : return "취소";
        }
    }
    //리스트토글엘리먼트show&hide
    const [ toggle,setToggle ] = useState({});
    /* ============================ 예약취소API ============================ */
    const isLock = useRef(false);
    const reservCancelAPI = async(item) => {//CANCEL_CODE: 7 (미입고 취소)
        if(!window.confirm("예약을 취소한 후에는 다시 복구할 수 없습니다.\n취소하시겠습니까?")) { return; }

        if (isLock.current){return;}
        isLock.current = true;
        const DATA = { MEMBER_CD:params.cd, PARTNER_CD:item.PARTNER_CD , ORDER_NO:item.ORDER_NO, CANCEL_CODE:7, CANCEL_CONTENT:"고객요청에 의한 취소" };
        const result = await axiosPostHandler(`memberReservCancelForAdmin.do`, DATA);
        setTimeout(() => {isLock.current = false;}, 300)
        if (result.message !== "SUCCESS") { props.onMessage(2, "예약취소에 실패하였습니다.\n잠시후 다시 시도해주시기 바랍니다."); }
        if (result.message === "SUCCESS") {
            pageRef.current = 20;
            props.onMessage(1, "예약취소가 완료되었습니다.")
            props.memberReservListAPI(0);
        }
    }
    /* ============================ 예약취소API ============================ */
    /* ============================ 결제취소팝업호출 ============================ */
    const [ isOpen,setOpen ] = useState({cancel:false});
    const dataRef = useRef({});
    const onDismissHandler = (name, value, item) => {
        if(value) { dataRef.current = item !== null ? item : {}; }
        setOpen((prevState) => {
            return { ...prevState, [name]:value }
        })
    }
    /* ============================ 결제취소팝업호출 ============================ */

    return (
        <> 
        {list.length > 0 ?
        <Element tag={`ul`}>
            {list.length > 0 ? list.map((item, i) =>
                <Element tag={`li`} key={item.ORDER_NO}>
                    <Element tag={`div`} className={styles.vehicleInfo}>
                        <Element tag={`div`} >
                            <Element tag={`div`} style={{width: "8.125rem"}}><Element tag={`label`}>{item.PARTNER_NAME}</Element></Element><hr/>
                            <Element tag={`div`} style={{width: "11.94rem"}} className={styles.center}><Element tag={`span`}>방문일 {item.RESERV_DT}</Element></Element><hr/>
                            <Element tag={`div`} style={{width: "10.25rem"}} className={styles.center}><Element tag={`label`}>{item.BRAND_KOR_NAME + " " + item.MODEL_KOR_NAME}</Element></Element><hr/>
                            <Element tag={`div`} style={{width: "4.25rem"}} className={styles.center}><Element tag={`label`}>{item.YEAR}년식</Element></Element><hr/>
                            <Element tag={`div`} className={[styles.stretch, ("state" + item.STATE)].join(" ")}><Element tag={`label`}>{itemStateFilter(item.STATE)}</Element></Element>
                            <a onClick={(e) => setToggle(toggle === item ? {} : item)}><FontAwesomeIcon icon={["fa-light", toggle === item ? "fa-chevron-up": "fa-chevron-down"].join(" ")} /></a>
                        </Element>
                    </Element>
                    {toggle === item ? 
                    <Element tag={`article`}>
                        <Element tag={`div`} className={styles.toggleContent}>
                            <Element tag={`div`} style={{width: "8.625rem"}}><Element tag={`span`}><FontAwesomeIcon icon="fa-light fa-scribble" />주행거리<Element tag={`strong`}>{item.KMLE}</Element>km</Element></Element><hr/>
                            <Element tag={`div`}>
                                <Element tag={`label`}>카테고리</Element>
                                {item.ITEM_LIST.length > 0 ? 
                                    <Element tag={`div`}>
                                    {item.ITEM_LIST.length > 0 ? item.ITEM_LIST.map((children, i) =>
                                        <>
                                        {children.CATEGORY === (i > 0 ? item.ITEM_LIST[i - 1].CATEGORY : null) || i === 0 ? <span>[{itemCategoryFilter(children.CATEGORY)}]</span> : null}
                                        <Element tag={`pre`}>{children.ITEM_NAME}</Element>
                                        </>
                                    ):null}
                                    </Element>
                                : null}
                            </Element><hr/>
                            <Element tag={`div`} >
                                <Element tag={`label`}>전달사항</Element>
                                {item.CONTENT !== undefined ? <Element tag={`div`}><Element tag={`pre`}>{item.CONTENT}</Element></Element> : null}
                            </Element><hr/>
                            <Element tag={`div`} >
                                <Element tag={`label`}>정비</Element>
                                {item.RESULT !== undefined ? <Element tag={`div`}><Element tag={`label`}>정비내용</Element><Element tag={`pre`}>{item.RESULT}</Element></Element> : null}
                                {item.REMARK !== undefined ? <Element tag={`div`}><Element tag={`label`}>특이사항</Element><Element tag={`pre`}>{item.REMARK}</Element></Element> : null}
                            </Element>
                        </Element>
                        <Element tag={`div`}  className={styles.toggleContainer}>
                            <Element tag={`ul`}>
                                <Element tag={`li`}>
                                    <Element tag={`div`} style={{width: "5.125rem"}}><Element tag={`span`}>{item.PAY_METHOD}</Element></Element><hr/>
                                    <Element tag={`div`} style={{width: "11.94rem"}}><Element tag={`span`}>예약일 {item.REG_DT}</Element></Element><hr/>
                                    <Element tag={`div`} style={{width: "7.25rem"}}><Element tag={`span`}><FontAwesomeIcon icon="fa-light fa-coins" />{item.AMOUNT}</Element></Element><hr/>
                                    <Element tag={`div`} className={styles.stretch}><Element tag={`span`}><FontAwesomeIcon icon="fa-light fa-coin" />{item.BALANCE_AMT}</Element></Element>
                                    <Element tag={`div`} className={styles.btnArea}>
                                        {admin.LEVEL <= 1 && item.STATE === "1" || item.STATE === "2" ? <Element anyprop={(e) => reservCancelAPI(item)} tag={`button`}><Element tag={`span`}>예약취소</Element></Element> : null }
                                        {admin.LEVEL <= 1 && item.STATE !== "6" && item.STATE !== "8" ? <Element anyprop={(e) => onDismissHandler('cancel', true, item)} tag={`button`}><Element tag={`span`}>결제취소</Element></Element> : null }
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    : null}
                </Element>
            ) : null}
        </Element>
        : <EmptyData/> }
        {isOpen.cancel ? <PaymentCancelPopup isOpen={isOpen.cancel} data={dataRef} onDismiss={onDismissHandler} /> : null}
        </>
    );
})

export default VehicleList;