import React from 'react';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//styles
import styles from 'src/asset/scss/styles/userPartnerList.module.scss';
//compWrapper
import ComnDiv from 'src/webapp/views/wrapper/Element';//공통엘리먼트//div

export default function Search(props) {
    //searchData
    const searchRef = props.search;
    //selectOption
    const option1 = [{name:'전체', value:'ALL'}, {name:'상호명', value:'1'}];
    const option2 = [{name:'전체', value:'ALL'}, {name:'승인대기', value:'0'}, {name:'구독 중', value:'1'}, {name:'이용제한', value:'2'}, {name:'접속제한', value:'3'}, {name:'비제휴업체', value:'4'}, {name:'탈퇴', value:'9'}, {name:'삭제', value:'D'}];
    //엔터이벤트
    const handleKeydown = (e) => {
        if(e.key === 'Enter') {
            props.onSearch(1);
            props.onState();
        }
    };

    return ( 
        <ComnDiv tag={`div`} className={styles.searchArea}>
            <ComnDiv tag={`div`}  className="selectArea">
                <SelectSearch options={option1} defaultValue={searchRef?.current[0]?.value === undefined ? option1[0].value : searchRef?.current[0]?.value}
                    ref={el => (searchRef.current[0] = el)} onChange={el => { (searchRef.current[0].value = el) }}
                    placeholder="상호명" />
            </ComnDiv>
            <ComnDiv tag={`div`} className="selectArea">
                <SelectSearch options={option2} defaultValue={searchRef?.current[1]?.value === undefined ? option2[0].value : searchRef?.current[1]?.value}
                    ref={el => (searchRef.current[1] = el)} onChange={el => { (searchRef.current[1].value = el); props.onSearch(1); props.onState(); }}
                    placeholder="상태" />
            </ComnDiv>
            <ComnDiv tag={`div`} className={styles.searchField}>
                <input ref={el => (searchRef.current[2] = el)} onKeyDown={handleKeydown}
                    type="text" placeholder="업체를 검색 해보세요" />
                <button><FontAwesomeIcon icon="fa-light fa-magnifying-glass" /></button>
            </ComnDiv>
        </ComnDiv>
    );
}